import styled from 'styled-components';

export const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 6px 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const AudioControls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
`;

export const PlayButton = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 0;
  margin: 0;
  
  &:hover {
    background-color: #357ab8;
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.5);
  }
`;

export const TimeDisplay = styled.div`
  font-size: 9px;
  color: #666;
  min-width: 50px;
  text-align: right;
`;

export const ProgressContainer = styled.div`
  flex: 1;
  height: 4px;
  background-color: #ddd;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  align-self: center;
`;

export const ProgressBar = styled.div<{ progress: number }>`
  height: 100%;
  background-color: #4a90e2;
  width: ${(props) => `${props.progress}%`};
  transition: width 0.1s linear;
`;

export const VolumeControl = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
`;

export const VolumeIcon = styled.div`
  color: #666;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const VolumeSlider = styled.input.attrs({
    type: 'range', min: 0, max: 1, step: 0.01,
})`
  -webkit-appearance: none;
  width: 60px;
  height: 3px;
  border-radius: 1.5px;
  background: #ddd;
  outline: none;
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #4a90e2;
    cursor: pointer;
  }
  
  &::-moz-range-thumb {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #4a90e2;
    cursor: pointer;
    border: none;
  }
`;
