import { useState } from 'react';
import useHttpQuery from 'data/firestore/useHttpQuery';
import { Button, Modal, Spinner } from 'react-rainbow-components';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { useParams } from 'react-router-dom';
import { StyledCopyToClipboardButton } from 'pages/App/Dataset/Settings/styled';
import ServiceCard from './ServiceCard';
import {
    ServicesContainer, ServicesHeader, ServicesWrapper,
    WarningIcon, WarningMeessage, UrlContainer, ModalContent, ModalTitle,
    LoadingState,
} from './styled';
import {
    Service, SelectedService, CheckoutLinkModalProps, Price,
} from './types';

const CheckoutLinkModal = ({
    url,
    isOpen = false,
    onRequestClose = () => {},
    isLoading = false,
}: CheckoutLinkModalProps) => (
    <Modal
        borderRadius="semi-rounded"
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={<ModalTitle>Agent Billing Link</ModalTitle>}
        size="medium"
    >
        <ModalContent>
            {isLoading ? (
                <LoadingState>
                    <Spinner size="small" variant="brand" />
                    <span>Generating billing link...</span>
                </LoadingState>
            ) : (
                <>
                    <p>Copy the link below to send the billing link to the customer.</p>
                    <UrlContainer>
                        <span>{url}</span>
                        <StyledCopyToClipboardButton
                            value={url}
                            borderRadius="semi-rounded"
                            variant="outline-brand"
                            size="small"
                        />
                    </UrlContainer>
                    <WarningMeessage>
                        <WarningIcon />
                        Notice that this billing link has an expiration time.
                    </WarningMeessage>
                </>
            )}
        </ModalContent>
    </Modal>
);

const Services = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        data,
        isLoading,
    } = useHttpQuery<{ data: Service[] }>({
        pathname: '/subscriptions/services',
        method: 'GET',
        server: process.env.REACT_APP_BASE_URL,
    });
    const { agentId } = useParams();
    const services = data?.data || [];
    const [selectedServices, setSelectedServices] = useState<SelectedService[]>([]);
    const {
        data: response,
        isLoading: isLoadingSession,
        mutate: createCheckoutSession,
    } = useHttpMutation<{ priceIds: string[] }, { url: string }>({
        pathname: `/agents/${agentId}/subscriptions/checkout-session`,
        method: 'POST',
        server: process.env.REACT_APP_BASE_URL,
    });

    const handleServiceSelect = (serviceId: string, price: Price | null) => {
        setSelectedServices((prev) => {
            // If price is null, remove the service from selection
            if (!price) {
                return prev.filter((s) => s.serviceId !== serviceId);
            }

            // If service already exists, update its price
            const existingIndex = prev.findIndex((s) => s.serviceId === serviceId);
            if (existingIndex >= 0) {
                const newSelected = [...prev];
                newSelected[existingIndex] = { serviceId, price };
                return newSelected;
            }

            // Add new service selection
            return [...prev, { serviceId, price }];
        });
    };

    const handleGenerateLink = async () => {
        await createCheckoutSession({
            body: {
                priceIds: selectedServices.map(
                    (service) => service.price?.id as string,
                ),
            },
        });
        setIsOpen(true);
    };

    return (
        <ServicesWrapper>
            <ServicesHeader>
                <div />
                {' '}
                {/* Empty div for flex spacing */}
                <Button
                    variant="brand"
                    label="Generate Subscription Link"
                    onClick={handleGenerateLink}
                    disabled={selectedServices.length === 0 || isLoadingSession}
                />
            </ServicesHeader>
            <ServicesContainer>
                {!isLoading && data && services.map((service) => (
                    <ServiceCard
                        key={service.id}
                        service={service}
                        onPriceSelect={(serviceId, price) => handleServiceSelect(serviceId, price)}
                        selectedPrice={
                            selectedServices.find((s) => s.serviceId === service.id)?.price
                        }
                    />
                ))}
            </ServicesContainer>
            <CheckoutLinkModal
                url={response?.url}
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                isLoading={isLoadingSession}
            />
        </ServicesWrapper>
    );
};

export default Services;
