import { Conversation } from 'data/firestore/session/conversation/types';
import { ParsedContent } from '../types';

interface Params {
    role: Conversation['role'];
    content: string | ParsedContent;
}

const transformString = (str: string) => {
    if (str) {
        const words = str.split('-');
        words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
        return words.join(' ');
    }
    return 'Unknown event';
};

const getEventName = ({ role, content }: Params) => {
    if (role === 'event') {
        return transformString((content as ParsedContent)?.event);
    }
    if (role === 'assistant') {
        return 'Assistant Message';
    }
    if (role === 'user') {
        return 'User Message';
    }
    return '';
};

export default getEventName;
