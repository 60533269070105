import { useNavigate } from 'react-router-dom';
import {
    Table, Column, Input,
} from 'react-rainbow-components';
import PageHeader from 'components/PageHeader';
import { MagnifyingGlass } from '@rainbow-modules/icons';
import useSpeechServices from 'data/firestore/speech/useCollection';
import { useState, useMemo } from 'react';
import {
    Header,
    StyledNavigationButtonColumn,
} from '../styled';

const SpeechList = () => {
    const { data, isLoading } = useSpeechServices();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    const navigateToService = (value: any) => {
        navigate(`/speech/${value.row.id}`);
    };

    const filteredData = useMemo(() => {
        if (!searchTerm.trim()) return data;
        return data?.filter(
            (item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ) || [];
    }, [data, searchTerm]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    return (
        <>
            <PageHeader title="Speech Services" />
            <Header>
                <Input
                    type="search"
                    size="small"
                    borderRadius="semi-rounded"
                    placeholder="Find a Speech Service..."
                    icon={<MagnifyingGlass />}
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </Header>

            <Table data={filteredData} isLoading={isLoading} keyField="id" variant="listview" className="rainbow-m-left_large rainbow-m-right_large">
                <Column
                    header="Name"
                    field="name"
                    headerAlignment="left"
                    cellAlignment="left"
                    component={StyledNavigationButtonColumn}
                    onClick={navigateToService}
                />
            </Table>
        </>
    );
};

export default SpeechList;
