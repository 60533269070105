import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    RenderIf, Tabset, Tab, Button,
} from 'react-rainbow-components';
import { PageHeaderTitle } from 'components/styled';
import context, { Context } from '../context';
import {
    SectionHeader,
    SectionDescription,
} from './styled';
import SubscriptionDetails from './SubscriptionDetails';
import UsageReports from './UsageReports';
import ActiveServices from './ActiveServices';
import Invoices from './Invoices';
import MetersUsage from './MetersUsage';
import Services from './Services';

const Billing = () => {
    const { agentData, isLoading } = useContext<Context>(context);
    const { agentId = '' } = useParams();
    const [activeTab, setActiveTab] = useState<string>('active-services');

    const handleCancelSubscription = () => {
        // TODO: Implement subscription cancellation logic
        console.log('Cancel subscription clicked');
    };

    return (
        <>
            <SectionHeader>
                <div>
                    <PageHeaderTitle>
                        Billing Information
                    </PageHeaderTitle>
                    <SectionDescription>
                        Manage the billing information for this agent and view usage reports.
                    </SectionDescription>
                </div>
                <RenderIf isTrue={Boolean(agentData?.billing)}>
                    <Button
                        variant="destructive"
                        label="Cancel Subscription"
                        onClick={handleCancelSubscription}
                    />
                </RenderIf>
            </SectionHeader>
            <RenderIf isTrue={!agentData?.billing}>
                <Services />
            </RenderIf>
            <RenderIf isTrue={Boolean(agentData?.billing)}>
                <SubscriptionDetails agentData={agentData} isLoading={isLoading} />
                <Tabset activeTabName={activeTab} variant="line" onSelect={(event, tabName) => setActiveTab(tabName)}>
                    <Tab label="Active Services" name="active-services" />
                    <Tab label="Invoices" name="invoices" />
                    <Tab label="Meters Usage" name="meters-usage" />
                    <Tab label="Usage Reports(deprecated)" name="usage-reports" />
                </Tabset>
                <RenderIf isTrue={activeTab === 'usage-reports'}>
                    <UsageReports agentId={agentId} />
                </RenderIf>
                <RenderIf isTrue={activeTab === 'invoices'}>
                    <Invoices subscriptionId={agentData?.billing?.subscriptionId} />
                </RenderIf>
                <RenderIf isTrue={activeTab === 'active-services'}>
                    <ActiveServices />
                </RenderIf>
                <RenderIf isTrue={activeTab === 'meters-usage'}>
                    <MetersUsage />
                </RenderIf>
            </RenderIf>
        </>
    );
};

export default Billing;
