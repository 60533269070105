import { useEffect, useRef, useState } from 'react';
import { EntityGet } from 'data/firestore/types';
import { Conversation } from 'data/firestore/session/conversation/types';
import Event from './event';
import Payload from './payload';
import { Container, ContentContainer } from './styled';

interface Props {
    data: EntityGet<Conversation>[];
}

const ConversationEvents = ({ data }: Props) => {
    const [initialData] = useState<EntityGet<Conversation>[]>(data);
    const [event, setEvent] = useState<EntityGet<Conversation> | null>(null);
    const bottomRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (data !== initialData) {
            bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Container>
            <ContentContainer>
                {data.map((item, index) => {
                    const { id } = item;
                    const isLastItem = data.length === index + 1;
                    const nextTimeMark = data[index + 1]?.timeMark;
                    const isSelected = event?.id === id;
                    return (
                        <Event
                            key={id}
                            data={item}
                            nextTimeMark={nextTimeMark}
                            isLastItem={isLastItem}
                            isSelected={isSelected}
                            onClick={() => setEvent(item)}
                        />
                    );
                })}
                <div ref={bottomRef} />
            </ContentContainer>
            {event && <Payload data={event} onClose={() => setEvent(null)} />}
        </Container>
    );
};

export default ConversationEvents;
