import styled from 'styled-components';
import SaraIcon from 'components/icons/sara';

const SaraAvatar = styled(SaraIcon)`
    width: 68px;
    height: 68px;
    flex-shrink: 0;
`;

export default SaraAvatar;
