import { Avatar } from 'react-rainbow-components';
import { ParsedContent } from '../types';
import {
    Container,
    Pill,
    Label,
    Field,
    UserIcon,
    PhoneIcon,
    VoiceIcon,
    MicIcon,
} from './styled';

interface CallData {
    customer?: {
        firstName?: string;
        lastName?: string;
        id?: string;
        photoURL?: string;
        preferredLanguage?: string;
    };
    phoneNumber?: {
        language?: string;
    };
    transcription?: {
        provider?: string;
        data?: {
            model?: string;
        };
    };
    voice?: {
        provider?: string;
        data?: {
            model?: string;
            voice?: string;
        };
    };
}

interface AnswerCallMessageProps {
    content: ParsedContent & {
        data: CallData;
    };
}

const AnswerCallMessage = ({ content }: AnswerCallMessageProps) => {
    const { data } = content;
    const {
        customer = {}, phoneNumber = {}, transcription = {}, voice = {},
    } = data;

    const customerName = customer?.firstName && customer?.lastName
        ? `${customer.firstName} ${customer.lastName}`
        : customer?.firstName || 'Unknown Customer';
    const customerId = customer?.id || '';

    return (
        <Container>
            <Pill>
                <Avatar
                    size="small"
                    src={customer?.photoURL}
                    icon={<UserIcon />}
                />
                <Label>{customerName}</Label>
                {customerId && (
                    <Field>
                        <b>id:</b>
                        {customerId}
                    </Field>
                )}
                {customer?.preferredLanguage && (
                    <Field>
                        <b>preferredLanguage:</b>
                        {customer.preferredLanguage}
                    </Field>
                )}
            </Pill>
            <Pill>
                <Avatar
                    size="small"
                    icon={<PhoneIcon />}
                />
                <Label>PhoneNumber</Label>
                <Field>
                    <b>language:</b>
                    {phoneNumber.language}
                </Field>
            </Pill>
            <Pill>
                <Avatar
                    size="small"
                    icon={<MicIcon />}
                />
                <Label>{transcription.provider}</Label>
                <Field>
                    <b>model:</b>
                    {transcription.data?.model}
                </Field>
            </Pill>
            <Pill>
                <Avatar
                    size="small"
                    icon={<VoiceIcon />}
                />
                <Label>{voice.provider}</Label>
                <Field>
                    <b>model:</b>
                    {voice.data?.model}
                </Field>
                <Field>
                    <b>voiceId:</b>
                    {voice.data?.voice}
                </Field>
            </Pill>
        </Container>
    );
};

export default AnswerCallMessage;
