import { useContext, useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Tile from 'components/BetterTile';
import context, { Context } from '../context';
import measureTimeBetween from './helpers/measureTimeBetween';
import { Event } from './types';
import {
    ChartContainer, HeaderText, CardContainer, Container, Header,
} from './styled';
import mean from './helpers/mean';

const Insights = () => {
    const { messages = [], isLoading } = useContext<Context>(context);

    const sortedEvents = useMemo(
        () => [...messages].sort(
            (a, b) => (a?.timeMark || 0) - (b?.timeMark || 0),
        ).map((message) => {
            if (message.role === 'event' && typeof message.content === 'string') {
                return {
                    ...message,
                    content: JSON.parse(message.content),
                };
            }
            return message;
        }),
        [messages],
    );
    const assitantDelays = measureTimeBetween(
        sortedEvents as unknown as Event[],
        'user-end-speak',
        'assistant-start-speak',
    );

    // Transform the array of numbers into the format expected by ResponsiveBar
    const chartData = useMemo(
        () => assitantDelays.map((delay, index) => ({
            id: `delay-${index}`,
            name: `Turn ${index + 1}`,
            delay: delay.toFixed(2),
        })),
        [assitantDelays],
    );

    if (isLoading) {
        return <h1>Loading...</h1>;
    }

    return (
        <Container>
            <Header>
                <Tile
                    label="Assistant Delays Mean"
                    value={mean(assitantDelays)}
                    unit="ms"
                />
                <Tile
                    label="Assistant Delays Min"
                    value={Math.min(...assitantDelays)}
                    unit="ms"
                />
                <Tile
                    label="Assistant Delays Max"
                    value={Math.max(...assitantDelays)}
                    unit="ms"
                />
            </Header>
            <CardContainer>
                <ChartContainer>
                    <HeaderText>Assistant Response Delays (ms)</HeaderText>
                    <ResponsiveBar
                        data={chartData}
                        keys={['delay']}
                        indexBy="name"
                        padding={0.3}
                        colors={['#0073e6']}
                        borderRadius={4}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 50,
                            left: 60,
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Time (ms)',
                            legendPosition: 'middle',
                            legendOffset: -40,
                        }}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -45,
                        }}
                        enableLabel
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelFormat={(value) => `${value} ms`}
                        animate
                        motionConfig="gentle"
                        labelPosition="end"
                        labelOffset={10}
                        label={(d) => `${d.value} ms`}
                    />
                </ChartContainer>
            </CardContainer>
        </Container>
    );
};

export default Insights;
