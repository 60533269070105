import { useEffect, useState } from 'react';
import {
    Outlet, useMatch, useNavigate, useParams,
} from 'react-router-dom';
import {
    Breadcrumbs, Breadcrumb, RenderIf, LoadingShape,
    Tabset,
    Tab,
} from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import PageHeader from 'components/PageHeader';
import getSpeech from 'data/firestore/speech/get';
import {
    PageTitle,
} from '../styled';

const SpeechDetail = () => {
    const { speechId = '' } = useParams();
    const [speechName, setSpeechName] = useState('');
    const navigate = useNavigate();
    const match = useMatch('speech/:agentId/:tabName');

    useEffect(() => {
        (async () => {
            try {
                const speech = await getSpeech(speechId);
                setSpeechName(speech?.name || speechId);
            } catch (error) {
                // No catch
            }
        })();
    }, [speechId]);

    return (
        <div>
            <PageHeader title={(
                <Breadcrumbs>
                    <Breadcrumb
                        label="All Speech Services"
                        onClick={() => navigate('/speech/list')}
                    />
                    <Breadcrumb
                        label={(
                            <>
                                <RenderIf isTrue={!isEmpty(speechName)}>
                                    <PageTitle>{speechName}</PageTitle>
                                </RenderIf>
                                <RenderIf isTrue={isEmpty(speechName)}>
                                    <LoadingShape />
                                </RenderIf>
                            </>
                        )}
                    />
                </Breadcrumbs>
            )}
            />
            <div className="rainbow-m-horizontal_large">
                <Tabset
                    variant="line"
                    onSelect={(event, eventName) => {
                        navigate(eventName);
                        event.preventDefault();
                    }}
                    activeTabName={match?.params?.tabName}
                >
                    <Tab name="voices" label="Voices" />
                    <Tab name="models" label="Models" />
                </Tabset>
            </div>
            <Outlet />
        </div>
    );
};

export default SpeechDetail;
