import styled from 'styled-components';

export const TileContainer = styled.div`
    background: linear-gradient(135deg, #f8fafc 0%, #eef2ff 100%);
    border-radius: 12px;
    padding: 20px;
    margin: 0 0 24px;
    border: 1px solid rgba(226, 232, 240, 0.8);
    transition: all 0.2s ease-in-out;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    box-shadow: 0 2px 4px rgba(148, 163, 184, 0.05);

    &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 8px rgba(148, 163, 184, 0.1);
        border-color: rgba(99, 102, 241, 0.2);
    }
`;

export const Label = styled.div`
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    color: #6366f1;
    display: flex;
    align-items: center;
    gap: 6px;
`;

export const Value = styled.div`
    font-size: 28px;
    font-weight: 700;
    color: #1e293b;
    letter-spacing: -0.5px;
`;

export const Unit = styled.span`
    font-size: 15px;
    color: #64748b;
    font-weight: 500;
    margin-left: 4px;
`;
