import styled from 'styled-components';
import { Avatar } from 'react-rainbow-components';
import SaraIcon from 'components/icons/sara';
import { Warning } from '@rainbow-modules/icons';

export const ServicesContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    padding: 1rem;
`;

export const StyledAvatar = styled(Avatar)`
    width: 100px;
    height: 68px;
`;

export const ServiceContainer = styled.div<{ $hasContent: boolean }>`
    width: 480px;
    position: relative;
    padding-bottom: 48px;
    border-radius: 16px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px, 
                rgba(0, 0, 0, 0.05) 0px 8px 15px, 
                rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    transition: transform 0.2s, box-shadow 0.2s;
    overflow: hidden;
    height: ${({ $hasContent }) => ($hasContent ? 'auto' : '260px')};

    &:hover {
        transform: translateY(-2px);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 20px,
                    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    }
`;

export const ServiceHeader = styled.div<{ $hasContent: boolean }>`
    padding: 1.5rem;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    border-bottom: ${({ $hasContent }) => ($hasContent ? '1px solid #e2e8f0' : 'none')};
    min-height: 120px;
`;

export const ServiceTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 0.5rem;
`;

export const ServiceName = styled.h3`
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #2d3748;
`;

export const ServiceDescription = styled.p`
    margin: 0;
    font-size: 15px;
    line-height: 1.6;
    color: #718096;
    max-width: 320px;
`;

export const CardContent = styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
    flex-direction: column;
    gap: 1.25rem;
    padding: 1.5rem;
    animation: fadeIn 0.3s ease-in-out;

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(-10px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &:first-of-type {
        color: #4a5568;
        font-size: 16px;
        line-height: 1.5;
    }
`;

export const PriceContainer = styled.div`
    margin-bottom: 12px;
    padding: 16px 20px;
    background: #f8fafc;
    border-radius: 12px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    border: 2px solid transparent;

    &:hover {
        background: #fff;
        border-color: #4299e1;
    }
`;

export const PriceName = styled.span`
    font-size: 22px;
    font-weight: 700;
    color: #1a365d;
    font-family: 'Inter', sans-serif;
    letter-spacing: -0.02em;
    text-transform: capitalize;
`;

export const PriceAmount = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
`;

export const PriceRow = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const RadioLabel = styled.label<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
    width: 100%;

    input[type="radio"] {
        appearance: none;
        width: 24px;
        height: 24px;
        border: 2px solid #cbd5e0;
        border-radius: 50%;
        margin: 0;
        position: relative;
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
        transition: all 0.2s ease;

        &:checked {
            border-color: #4299e1;
            background: #4299e1;

            &:after {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: white;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &:hover:not(:checked):not(:disabled) {
            border-color: #4299e1;
        }
    }
`;

export const ToggleContainer = styled.div`
    position: absolute;
    bottom: 16px;
    left: 24px;
    right: 24px;
    padding: 8px 0;
    border-top: 1px solid #edf2f7;
`;

export const Table = styled.table`
    width: 100%;
    margin-top: 16px;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    border: 1px solid #e2e8f0;
`;

export const TableHeader = styled.th`
    padding: 14px 16px;
    text-align: left;
    background: #f7fafc;
    color: #4a5568;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 2px solid #e2e8f0;
    white-space: nowrap;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &:first-child {
        padding-left: 24px;
    }

    &:last-child {
        padding-right: 24px;
    }
`;

export const TableCell = styled.td`
    padding: 14px 16px;
    color: #2d3748;
    font-size: 14px;
    border-bottom: 1px solid #edf2f7;
    transition: background-color 0.2s;

    &:first-child {
        padding-left: 24px;
        font-weight: 500;
    }

    &:last-child {
        padding-right: 24px;
    }
`;

export const TableRow = styled.tr`
    &:hover {
        background-color: #f7fafc;
    }

    &:last-child ${TableCell} {
        border-bottom: none;
    }
`;

export const PriceTableContainer = styled.div`
    margin: 16px 0;
    padding: 0 12px;
`;

export const FeatureList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 16px 0;
    border-top: 1px solid #edf2f7;
    padding-top: 16px;
`;

export const FeatureItem = styled.li<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
    color: #4a5568;
    font-size: 15px;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
    transition: opacity 0.2s;

    span[role="img"] {
        color: #48bb78;
        font-size: 18px;
    }
`;

export const StyledSaraIcon = styled(SaraIcon)`
    width: 68px;
    height: 68px;
    flex-shrink: 0;
`;

export const CardFooter = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 24px;
    background: #f8fafc;
    border-top: 1px solid #e2e8f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FooterText = styled.span`
    font-size: 14px;
    color: #4a5568;
    font-weight: 500;
`;

export const PriceInfo = styled.div`
    display: flex;
    align-items: baseline;
    gap: 12px;
`;

export const StartingPrice = styled.span`
    font-size: 20px;
    color: #2d3748;
    font-weight: 600;
    
    &::before {
        content: 'Starting at ';
        font-size: 16px;
        color: #718096;
        font-weight: 500;
    }
`;

export const ServicesHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 100%;
`;

export const ServicesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const WarningIcon = styled(Warning)`
    width: 20px;
    height: 20px;
    color: #f4b740;
    flex-shrink: 0;
`;

export const WarningMeessage = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: #7c6514;
    font-size: 14px;
    margin-top: 24px;
    background: #fef6e6;
    padding: 12px 16px;
    border-radius: 8px;
`;

export const UrlContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    background: #f8f9fa;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #e2e8f0;

    span {
        flex: 1;
        word-break: break-all;
        font-family: monospace;
        color: #2d3748;
        font-size: 14px;
    }
`;

export const ModalContent = styled.div`
    padding: 32px;
    max-width: 600px;
    
    > p {
        margin: 0 0 24px;
        font-size: 15px;
        color: #4a5568;
        line-height: 1.5;
    }
`;

export const ModalTitle = styled.h3`
    margin: 0;
    padding: 24px 32px;
    font-size: 20px;
    font-weight: 600;
    color: #2d3748;
    border-bottom: 1px solid #e2e8f0;
`;

export const LoadingState = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    min-height: 120px;
    color: #4a5568;
    font-size: 15px;
`;
