import styled from 'styled-components';
import SaraAvatar from 'components/SaraAvatar';

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;
`;

const TextContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const ResourceTitle = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    color: #1a1a1a;
    letter-spacing: -0.5px;
    line-height: 1.2;
`;

const ResourceDescription = styled.p`
    font-size: 15px;
    color: #4a5568;
    margin: 0;
    line-height: 1.5;
    font-weight: 400;
    opacity: 0.9;
`;

interface ResourceHeaderProps {
    name: string;
    description: string;
}

const ResourceHeader = ({ name, description }: ResourceHeaderProps) => (
    <HeaderContainer>
        <SaraAvatar />
        <TextContainer>
            <ResourceTitle>{name}</ResourceTitle>
            <ResourceDescription>{description}</ResourceDescription>
        </TextContainer>
    </HeaderContainer>
);

export default ResourceHeader;
