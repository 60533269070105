import { doc, getDoc, getDocFromServer } from "firebase/firestore";
import { db } from "../firebase";
import { GetServiceOpts, EntityGet, EntityServerGet } from "../types";
import { Speech } from "./types";
import { transformMetadata, processTimestampFields } from "../utils";

export type SpeechGetService<T> = (
  id: string,
  opts?: GetServiceOpts
) => Promise<T | null>;

const get: SpeechGetService<EntityGet<Speech>> = async (id, opts = {}) => {
  const docRef = doc(db, `/speech/${id}`);
  const { disableCache } = opts;
  const getFn = disableCache ? getDocFromServer : getDoc;
  const docSnap = await getFn(docRef);
  if (docSnap.exists()) {
    return processTimestampFields(
      transformMetadata({
        ...docSnap.data(),
        id: docSnap.id,
      } as EntityServerGet<Speech>)
    );
  }
  return null;
};

export default get;
