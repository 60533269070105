import styled from 'styled-components';
import { Badge, HelpText } from 'react-rainbow-components';
import { color, getConfidenceColors, getConfidenceColor } from 'theme';

export const Container = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
`;

export const ItemContainer = styled.li<{ isSelected?: boolean; onClick?: () => void | undefined; backgroundColor?: string }>`
    display: flex;
    padding: 0 8px;
    border-radius: 4px;
    ${(props) => props.onClick && `
        cursor: pointer;

        :hover {
            background-color: rgb(251, 249, 255);
        }
    `}

    ${(props) => props.isSelected && `
        background-color: rgb(251, 249, 255);
    `}

    :last-child {
        padding-bottom: 20px;
    }

`;

export const TimeMark = styled.span`
    min-width: 90px;
    font-size: 14px;
    color: ${color('text.header')};
    padding-top: 2px;
`;

export const Line = styled.div<{ isLastItem?: boolean; color?: string }>`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    margin-right: 16px;

    ${(props) => !props.isLastItem && `
        ::before {
            content: '';
            background-color: ${props.color || '#D8D8D8'};
            height: 100%;
            width: 1px;
            position: absolute;
            right: 50%;
            left: 50%;
        }
    `}
`;

export const IconContainer = styled.div<{ color?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    min-width: 2rem;
`;

export const PhoneIconContainer = styled.span`
    width: 30px;
    height: 30px;
    background-color: #B8B4C1;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    box-shadow: 0px 0px 2px 0px #B8B4C1;
`;

export const EventCircle = styled.div<{ color?: string }>`
    border-radius: 50%;
    background-color: ${(props) => props.color || '#B8B4C1'};
    padding: 2px;
    box-shadow: 0px 0px 3px 0px ${(props) => props.color || '#B8B4C1'};
`;

export const Circle = styled.div<{ color?: string }>`
    border-radius: 50px;
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.color || '#B8B4C1'};
    border: 3px solid white;
`;

export const Content = styled.div`
    min-height: 72px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2px;
`;

export const Label = styled.h2`
    font-size: 16px;
    font-weight: 500;
    color: rgb(161, 130, 224);
    margin: 0 0 4px;
    line-height: 1.3;
    letter-spacing: 0.01em;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Duration = styled.h3`
    font-size: 9px;
    font-weight: 500;
    color: ${color('text.header')};
    margin: 4px 0;
    padding: 1px 4px;
    background-color: rgba(127, 103, 190, 0.08);
    border-radius: 6px;
    display: inline-flex;
    width: fit-content;
    letter-spacing: 0.01em;
    box-shadow: 0 1px 1px rgba(127, 103, 190, 0.1);
    border: 1px solid rgba(127, 103, 190, 0.12);
`;

export const MessageContainer = styled.div`
    border: none;
    background-color: #d1e7fd;
    border-radius: 18px 18px 18px 4px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 85%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: all 0.2s ease;
    
    &:hover {
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
    }
    
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -12px;
        width: 24px;
        height: 24px;
        background-color: #d1e7fd;
        clip-path: polygon(0 0, 100% 100%, 100% 0);
    }
    
    /* Adjust styling based on role */
    ${(props) => props.role === 'user' && `
        align-self: flex-end;
        background-color: #e2ffc7;
        border-radius: 18px 18px 4px 18px;
        
        &:before {
            left: auto;
            right: -12px;
            background-color: #e2ffc7;
            clip-path: polygon(0 0, 0 100%, 100% 0);
        }
    `}
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid ${color('border.divider')};
    padding-bottom: 4px;
    margin-bottom: 4px;
`;

export const RoleContainer = styled.div`
    width: 90px;
    min-width: 90px;
    align-items: flex-start;
`;

export const RoleBadge = styled(Badge)<{ label: string }>`
    padding: 4px 8px;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 1px;
    width: 100%;
    max-width: 80px;
    
    ${(props) => (props.label === 'assistant') && `
        color: ${color('brand.main')(props)};
        background: ${color('brand.light')(props)};
    `};

    ${(props) => (props.label === 'user') && `
        color: #F7C02B;
        background: rgba(247, 192, 43, 0.28);
    `};
`;

export const MessageContent = styled.p`
    font-size: 14px;
    color: ${color('text.label')};
    margin: 0;
    padding: 4px 0;
    line-height: 1.5;
    flex: 1;
    word-break: break-word;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.01em;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    max-width: 100%;
`;

export const ContentContainer = styled.ul`
    padding: 24px 20px 24px 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const PayloadContainer = styled.div`
    min-width: 350px;
    width: 350px;
    flex-grow: 0;
    flex-shrink: 0;
    border-left: 1px solid ${color('border.divider')};
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-top: 18px;
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
`;

export const PayloadHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-left: 20px;
    padding-right: 12px;
`;

export const PayloadTitle = styled.h1`
    font-size: 18px;
    color: ${color('text.label')};
`;

export const PayloadContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
`;

export const MetadataContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
`;

export const StyledBadge = styled(Badge)<{ value: number }>`
    flex-shrink: 0;
    ${getConfidenceColors}
`;

export const NativeButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 80px;
    &:hover {
        opacity: 0.8;
    }
`;

export const MoreDetailsContainer = styled.div`
    display: flex;
    flex-grow: 1;
    width: 100%;
    margin-top: 8px;
    transition: all 0.2s ease-in-out;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex-grow: 1;
    background: ${color('background.main')};
    border: 1px solid ${color('border.disabled')};
    border-radius: 8px;
    width: 100%;
    padding: 8px 10px;
    margin-left: 90px; /* Align with message content */
    max-width: calc(100% - 100px); /* Keep some margin on the right */
    transition: all 0.2s ease;
`;

export const DescriptionText = styled.p`
    font-size: 13px;
    color: ${color('text.label')};
    margin: 0;
    line-height: 1.3;
`;

export const DescriptionTitle = styled.h2`
    font-size: 13px;
    font-family: 'Lato Bold';
    color: ${color('text.main')};
    margin: 0;
`;

export const Word = styled.span<{ value?: number, isHover: boolean }>`
    ${getConfidenceColor}
    transition: all 0.15s ease;
    position: relative;
    
    ${(props) => props.isHover && `
        background-color: rgb(240, 240, 240);
        border-radius: 3px;
        padding: 0 2px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    `}
`;

export const Text = styled.p`
    font-size: 14px;
    color: ${color('text.secondary')};
    margin: 0;
    padding: 4px 0;
    line-height: 1.5;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.01em;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    max-width: 100%;
    font-style: italic;
    opacity: 0.85;
`;

export const LanguagePill = styled.span`
    display: inline-block;
    padding: 2px 8px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 13px;
    background-color: rgba(127, 103, 190, 0.15);
    color: ${color('text.main')};
    margin: 0 4px;
    letter-spacing: 0.02em;
    box-shadow: 0 1px 2px rgba(127, 103, 190, 0.2);
    border: 1px solid rgba(127, 103, 190, 0.2);
    font-style: normal;
`;

export const LanguageChangeContainer = styled.p`
    font-size: 14px;
    color: ${color('text.secondary')};
    margin: 0;
    padding: 4px 0;
    line-height: 1.5;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.01em;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
`;

export const StyledHelpText = styled(HelpText)`
    font-size: 12px;
    & > svg {
        width: 16px;
        height: 16px;
        
    }
    & > svg path {
        fill: rgba(127, 103, 190, 0.7);
    }
`;
