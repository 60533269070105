import { useCallback } from 'react';
import {
    useParams, useMatch, Outlet,
} from 'react-router-dom';
import { orderBy, query } from 'firebase/firestore';
import {
    Breadcrumb,
    Breadcrumbs,
    Button,
    RenderIf,
    Tab,
    Tabset,
    Badge,
    ButtonMenu,
    MenuItem,
} from 'react-rainbow-components';
import useSession from 'data/firestore/session/use';
import useConversation from 'data/firestore/session/conversation/useCollection';
import formatter from 'data/services/date/formatter';
import { EntityGet } from 'data/firestore/types';
import { Session } from 'data/firestore/session/types';
import PageHeader from 'components/PageHeader';
import { PageHeaderTitle } from 'components/styled';
import ConversationPlayer from 'components/ConversationPlayer';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import useSessionMetadata from 'hooks/useSessionMetadata';
import { PageTitle } from '../styled';
import CallStatusBadge from '../../../../components/CallStatusBadge';
import {
    Container,
    LeftContainer,
    ScrollContent,
    SubHeaderContainer,
} from './styled';
import useConversationRawEvents from './useConversationRawEvents';
import useRecording from './useRecording';
import { Provider } from './context';
import getRegions from './getRegions';

const ConversationDetailsLayout = () => {
    const { conversationId = '' } = useParams();
    const match = useMatch('history/conversations/:conversationId/:tabName');
    const { data, isLoading } = useSession(conversationId as string);
    const { metadata, isLoading: isLoadingMetadata } = useSessionMetadata(conversationId as string, ['transcript']);
    const { recordingUrl, downloadRecording } = useRecording(conversationId, data?.recordingId);
    const {
        downloadJSONL,
        downloadWAV,
    } = useConversationRawEvents(conversationId);
    const { data: messages, isLoading: isLoadingMessages } = useConversation(conversationId, {
        listQueryFn: (ref) => query(ref, orderBy('createdAt')),
    });
    const navigate = useNavigateWithQuery();

    const copyConversation = useCallback(() => {
        const text = messages
            .filter((msg) => msg.role === 'user' || msg.role === 'assistant')
            .map((msg) => `${msg.role}: ${msg.content}`).join('\n');
        navigator.clipboard.writeText(text);
    }, [messages]);

    const audioConversationRegions = recordingUrl
        ? getRegions(messages)
        : [];

    return (
        <Container>
            <PageHeader
                title={(
                    <LeftContainer>
                        <Breadcrumbs>
                            <Breadcrumb
                                label="All Conversations"
                                onClick={() => navigate('/history/conversations')}
                            />
                            <Breadcrumb label={<PageTitle>{conversationId}</PageTitle>} />
                        </Breadcrumbs>

                        <SubHeaderContainer>
                            <PageHeaderTitle>
                                {formatter.format((data as EntityGet<Session>)?.createdAt)}
                            </PageHeaderTitle>
                            <Badge label={data?.direction} borderRadius="semi-square" size="small" variant="lightest" />
                        </SubHeaderContainer>
                        <CallStatusBadge status={data?.status} />
                    </LeftContainer>
                )}
                actions={(
                    <>
                        <Button
                            label="Copy Conversation"
                            borderRadius="semi-rounded"
                            size="small"
                            onClick={copyConversation}
                        />
                        <ButtonMenu
                            label="Download"
                            borderRadius="semi-rounded"
                            buttonSize="small"
                            buttonVariant="neutral"
                        >
                            <MenuItem label="JSONL File" onClick={downloadJSONL} />
                            <MenuItem label="Audio WAV Only" onClick={downloadWAV} />
                            <MenuItem label="Conversation Audio" onClick={downloadRecording} />
                        </ButtonMenu>
                    </>
                )}
            />

            <div className="rainbow-p-horizontal_large">
                <RenderIf isTrue={!!recordingUrl}>
                    <ConversationPlayer
                        url={recordingUrl}
                        conversation={audioConversationRegions}
                    />
                </RenderIf>
            </div>

            <div className="rainbow-m-top_small rainbow-p-horizontal_medium">
                <Tabset
                    variant="line"
                    onSelect={(event, eventName) => {
                        navigate(eventName);
                        event.preventDefault();
                    }}
                    activeTabName={match?.params?.tabName}
                >
                    <Tab name="debug" label="Debug View" />
                    <Tab name="insights" label="Insights" />
                    <Tab name="transcript" label="Transcript" />
                    <Tab name="details" label="Details" />
                </Tabset>
            </div>
            <ScrollContent>
                <Provider
                    value={{
                        sessionData: data,
                        isLoading: isLoading || isLoadingMessages || isLoadingMetadata,
                        messages,
                        metadata,
                    }}
                >
                    <Outlet />
                </Provider>
            </ScrollContent>
        </Container>
    );
};

export default ConversationDetailsLayout;
