// colors
export const BRAND = '#A182E0';
export const SUCCESS = '#75d093';
export const WARNING = '#eb8f00';
export const ERROR = '#fd5556';
export const BACKGROUND = '#FBF9FF';
export const TEXT_MAIN = '#7F67BE';
export const ACTION_HIGHLIGHT = '#EAE0FF';

export const CONFIDENCE_HIGH = 0.8;
export const CONFIDENCE_LOW = 0.45;

// responsive
export const screenSize = {
    mobile: 520,
    tablet: 820,
    laptop: 1024,
};
export const mediaSize = {
    mobile: `(max-width: ${screenSize.mobile}px)`,
    tablet: `(max-width: ${screenSize.tablet}px)`,
    laptop: `(max-width: ${screenSize.laptop}px)`,
};
export const mediaSizeMinWidth = {
    mobile: `(min-width: ${screenSize.mobile}px)`,
    tablet: `(min-width: ${screenSize.tablet}px)`,
    laptop: `(min-width: ${screenSize.laptop}px)`,
};

// sizes
export const TOP_BAR_HEIGHT = 76;

export const ITEMS_PER_PAGE = 20;

export const ADD_MODIFY_AGENT_DRAWER = 'add-modify-agent__drawer';
export const ADD_CAMPAIGN_DRAWER = 'add-campaign__drawer';
export const ADD_DOCUMENT_MODAL = 'add-document';
export const SHOW_CHUNK_DETAILS_MODAL = 'show-chunk-details';
export const SHOW_USAGE_RECORD_DETAILS_MODAL = 'show-usage-record-details';
export const CREATE_MODEL_MODAL = 'create-model-modal';
export const BUILDING_BLOCK_MODAL = 'building-block-modal';
export const INSERT_BUILDING_BLOCK_MODAL = 'insert-building-block-modal';
export const COMPLETION_SETTINGS_DRAWER = 'completion-settings-drawer';

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

// supported agent languages
export const availableLanguages = [
    { name: 'en', label: 'English (US)' },
    { name: 'es', label: 'Español (US)' },
];

// states of USA
export const states = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'American Samoa', abbreviation: 'AS' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'District Of Columbia', abbreviation: 'DC' },
    { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Guam', abbreviation: 'GU' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Marshall Islands', abbreviation: 'MH' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Northern Mariana Islands', abbreviation: 'MP' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Palau', abbreviation: 'PW' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Puerto Rico', abbreviation: 'PR' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virgin Islands', abbreviation: 'VI' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
];

export const BASIC_MODELS = [
    'gpt-3.5-turbo-16k',
    'gpt-3.5-turbo-1106',
    'gpt-3.5-turbo-0125',
    'gpt-3.5-turbo',
    'gpt-4',
    'gpt-4-32k',
    'gpt-4-1106-preview',
    'gpt-4-0125-preview',
    'gpt-4-turbo-2024-04-09',
    'gpt-4-turbo',
    'gpt-4o-2024-05-13',
    'gpt-4o-2024-08-06',
];

// colors
export const WARNING_LIGHTER = 'rgba(248, 197, 54, 0.16)';
export const WHITE = '#fff';
export const DIVIDER = 'rgba(0, 0, 0, 0.10)';
export const AMERICAN_YELLOW = '#F6B704';

// PURPLE Palette
export const PURPLE_100 = '#ecebff';
export const PURPLE_200 = '#c7c2ff';
export const PURPLE_300 = '#a098f6';
export const PURPLE_400 = '#9a91f9';
export const PURPLE_500 = '#7367f0';
export const PURPLE_600 = '#3d30bc';
export const PURPLE_700 = '#1f149a';
export const PURPLE_800 = '#0e0661';
export const PURPLE_900 = '#060042';

// TEAL Palette
export const TEAL_100 = '#C6F2FC';
export const TEAL_200 = '#AEEDFA';
export const TEAL_300 = '#95E5F6';
export const TEAL_400 = '#7EDCF0';
export const TEAL_500 = '#5CCCE5';
export const TEAL_600 = '#37ABC6';
export const TEAL_700 = '#197991';
export const TEAL_800 = '#0A4D5D';
export const TEAL_900 = '#03272E';

// GREEN Palette
export const GREEN_100 = '#D7EBDE';
export const GREEN_200 = '#C1E3CD';
export const GREEN_300 = '#B9E2C7';
export const GREEN_400 = '#A7DFBA';
export const GREEN_500 = '#8ED8A7';
export const GREEN_600 = '#54C279';
export const GREEN_700 = '#29914b';
export const GREEN_800 = '#135A2A';
export const GREEN_900 = '#082D14';

// YELLOW Palette
export const YELLOW_100 = '#FAF7D8';
export const YELLOW_200 = '#F9F2AB';
export const YELLOW_300 = '#F6EB81';
export const YELLOW_400 = '#ECDE5C';
export const YELLOW_500 = '#DFCF4A';
export const YELLOW_600 = '#BCAE3A';
export const YELLOW_700 = '#94892C';
export const YELLOW_800 = '#6B621D';
export const YELLOW_900 = '#3B370D';

// ORANGE Palette
export const ORANGE_100 = '#FDF8F4';
export const ORANGE_200 = '#FDEAD8';
export const ORANGE_300 = '#FCD0A8';
export const ORANGE_400 = '#F7B67B';
export const ORANGE_500 = '#F2A356';
export const ORANGE_600 = '#DD8134';
export const ORANGE_700 = '#B05E1B';
export const ORANGE_800 = '#763C0D';
export const ORANGE_900 = '#452306';

// RED Palette
export const RED_100 = '#F4DFE3';
export const RED_200 = '#F6B9C5';
export const RED_300 = '#EE8A9C';
export const RED_400 = '#E3677E';
export const RED_500 = '#C83B54';
export const RED_600 = '#A12138';
export const RED_700 = '#7A1125';
export const RED_800 = '#500816';
export const RED_900 = '#240208';

// GREY Palette
export const GREY_50 = '#EBEBEC';
export const GREY_100 = '#EFEFF2';
export const GREY_200 = '#C0C0C1';
export const GREY_300 = '#919194';
export const GREY_400 = '#636369';
export const GREY_500 = '#393841';
export const GREY_600 = '#262530';
export const GREY_700 = '#14141e';
export const GREY_800 = '#0a0912';
export const GREY_900 = '#05040b';

// backgrounds
export const HIGHLIGHT = 'rgba(75, 70, 92, 0.04)';

// box-shadows
export const CARD_SHADOW = '0px 4px 18px 0px rgba(75, 70, 92, 0.10)';
export const BAR_SHADOW = '0px 2px 4px 0px rgba(165, 163, 174, 0.30)';

// algolia indexes
export const ALGOLIA_INDEX_SESSIONS = 'sessions';
export const ALGOLIA_INDEX_INBOX = 'inbox';
export const ALGOLIA_INDEX_BLOCKED_NUMBER = 'blockednumbers';
export const ALGOLIA_INDEX_CUSTOMERS = 'customers';
export const ALGOLIA_INDEX_WORKPLACES = 'workplaces';
export const ALGOLIA_INDEX_AFFILIATES = 'affiliates';
export const ALGOLIA_INDEX_OPPORTUNITIES = 'opportunities';
export const ALGOLIA_INDEX_OPPORTUNITIES_UPDATED_AT_DESC = 'opportunities_updatedAt_desc';
export const ALGOLIA_INDEX_OPPORTUNITIES_UPDATED_AT_ASC = 'opportunities_updatedAt_asc';
export const ALGOLIA_INDEX_OPPORTUNITIES_CREATED_AT_DESC = 'opportunities_createdAt_desc';
export const ALGOLIA_INDEX_OPPORTUNITIES_CREATED_AT_ASC = 'opportunities_createdAt_asc';
export const ALGOLIA_INDEX_OPPORTUNITIES_SCORE_DESC = 'opportunities_score_desc';
export const ALGOLIA_INDEX_OPPORTUNITIES_SCORE_ASC = 'opportunities_score_asc';

// pagination
export const PAGE_SIZE = 10;
export const CHAT_PAGE_SIZE = 30;

// modals
export const ADD_EDIT_CUSTOMER_MODAL = 'add-edit-customer-modal';
export const ADD_EDIT_WORKPLACE_MODAL = 'add-edit-workplace-modal';
export const ADD_EDIT_ENTITY_TAG_MODAL = 'add-edit-entity-tag-modal';
export const ADD_EDIT_TEAM_USER = 'add-edit-team-user-modal';
export const EDIT_PHONE_LANGUAGE = 'edit-phone-language-modal';
export const ADD_EDIT_CALENDAR_EVENT_TYPE = 'add-edit-calendar-event-type-modal';
export const ADD_EDIT_CALENDAR_EVENT_STATUS = 'add-edit-calendar-event-status-modal';
export const ADD_RESCHEDULE_CALENDAR_EVENT = 'add-reschedule-calendar-event-modal';
export const CALENDAR_EVENT_DETAILS = 'calendar-event-details-modal';
export const ADD_EDIT_CALENDAR_EVENT_TYPE_NOTIFICATION_RULE = 'add-edit-calendar-event-type-notification-rule-modal';
export const ADD_EDIT_RESPONDER_MODAL = 'add-edit-responder-modal';
export const ADD_EDIT_LLM_RESPONDER_MODAL = 'add-edit-llm-responder-modal';
export const IMPORT_VOIP_CONVERSATION_MODAL = 'import-voip-conversation-modal';
export const VOIP_INSTRUCTIONS_TESTING_MODAL = 'voip-instruction-testing-modal';
export const RESPONDER_INSTRUCTIONS_TESTING_MODAL = 'responder-instruction-testing-modal';
export const LLM_RESPONDER_INSTRUCTIONS_TESTING_MODAL = 'llm-responder-instruction-testing-modal';
export const HELP_ME_WRITE_MODAL = 'help-me-write-modal';
export const CUSTOMER_CALL_MODAL = 'customer-call-modal';
export const ADD_EDIT_OPPORTUNITY_PIPELINE_STAGE_MODAL = 'add-edit-opportunity-pipeline-stage-modal';
export const CONFIG_OPPORTUNITY_PIPELINE_STAGE_TRACKING_MODAL = 'config-opportunity-pipeline-stage-tracking-modal';
export const ADD_EDIT_OPPORTUNITY_MODAL = 'add-edit-opportunity-modal';
export const ADD_EDIT_PHONE_NUMBER_MODAL = 'add-edit-phone-number-modal';
export const ADD_EDIT_WIDGET_CHATBOT_MODAL = 'add-edit-widget-chatbot-modal';
export const ADD_EDIT_PIPELINE = 'add-edit-pipeline-modal';

// drawer
export const OPPORTUNITY_DETAILS_DRAWER = 'opportunity-details-drawer';
export const SMS_CHAT_DRAWER = 'sms-chat-modal';
export const CUSTOMER_PANEL_DRAWER = 'customer-panel-drawer';
export const TASK_DETAILS_DRAWER = 'task-details-drawer';
export const ADD_EDIT_FREQUENT_MESSAGE = 'add-edit-frequent-message-drawer';
export const FREQUENT_MESSAGE_SETTINGS = 'frequent-message-settings-drawer';
export const PIPELINE_STAGE_INSIGHTS_DETAILS_DRAWER = 'pipeline-stage-insights-details-drawer';
export const AI_MESSAGE_SENT_IN_CHANNEL_DETAILS_DRAWER = 'ai-message-sent-in-channel-details-drawer';

// accepted media formats
export const IMAGE_FORMATS_ACCEPTED_STRING = 'image/jpeg,image/jpg,image/gif,image/png,image/bmp,image/tiff,image/webp,.pdf';

// Local storage
export const SELECTED_TOPICS_STORAGE_KEY = 'selectedTopics';
export const OPPORTUNITY_BOARD_STORAGE_KEY = 'opportunity-board';
