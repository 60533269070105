import styled from 'styled-components';

export const ResourcesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    padding: 32px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
`;

export const ResourceCard = styled.div`
    background: white;
    border-radius: 12px;
    padding: 24px;
    width: 100%;
    border: 2px solid #e6e8eb;
    transition: border-color 0.2s ease;

    &:hover {
        border-color: #0073e6;
    }
`;

export const ResourceTitle = styled.h3`
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 12px 0;
    color: #2f3337;
`;

export const ResourceDescription = styled.p`
    font-size: 14px;
    color: #666;
    margin: 0 0 24px 0;
`;

export const ChartContainer = styled.div`
    height: 300px;
    width: 100%;
    padding: 16px;
`;
