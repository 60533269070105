import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useHttpMutation from 'data/firestore/useHttpMutation';
import useHttpQuery from 'data/firestore/useHttpQuery';
import {
    Modal, Button,
    RenderIf, Picklist, Option,
    Textarea,
} from 'react-rainbow-components';
import { showAppMessage } from '@rainbow-modules/app';
import { ModalTitle } from '../styled';

interface AddModelModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    speechId: string;
    existingModelsIds: string[];
}

interface AvailableModel {
    id: string;
    name: string;
    description?: string;
    languages?: {
        code: string;
        name: string;
    }[];
}

interface PicklistValue {
    label?: string;
    name?: string | number;
    value?: any;
}

const AddModelModal = (props: AddModelModalProps) => {
    const {
        isOpen, onRequestClose, speechId, existingModelsIds,
    } = props;
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [modelId, setModelId] = useState('');
    const [description, setDescription] = useState('');
    const [languages, setLanguages] = useState<string[]>([]);
    const [selectedModel, setSelectedModel] = useState<PicklistValue>();
    const [filteredModels, setFilteredModels] = useState<AvailableModel[]>([]);

    // Fetch available models from your API
    const { data: availableModels, isLoading: isLoadingModels } = useHttpQuery<{
        data: AvailableModel[]
    }>({
        pathname: `/speech/${speechId}/available-models`,
        method: 'GET',
    });

    useEffect(() => {
        if (availableModels?.data) {
            const filtered = availableModels.data.filter(
                (model) => !(existingModelsIds || []).includes(model.id),
            );
            setFilteredModels(filtered);
        }
    }, [availableModels, existingModelsIds]);

    useEffect(() => {
        if (selectedModel && selectedModel.value) {
            const model = availableModels?.data.find((m) => m.id === selectedModel.value);
            if (model) {
                setName(model.name);
                setModelId(model.id);
                setDescription(model.description || '');
                setLanguages(model.languages?.map((lang) => lang.code) || []);
            }
        }
    }, [availableModels?.data, selectedModel]);

    const { mutateAsync: addModel, isLoading } = useHttpMutation({
        method: 'PUT',
    });

    const handleSearch = (searchText: string) => {
        const filtered = availableModels?.data.filter((model) => {
            const searchableText = `${model.name} ${model.id}`.toLowerCase();
            const search = searchText.toLowerCase();
            return searchableText.includes(search);
        }).filter(
            (model) => !(existingModelsIds || []).includes(model.id),
        );
        setFilteredModels(filtered || []);
    };

    const requestClose = () => {
        setName('');
        setModelId('');
        setDescription('');
        setSelectedModel(undefined);
        onRequestClose();
    };

    const submit = async () => {
        if (!name.trim()) {
            showAppMessage({
                variant: 'error',
                timeout: 5000,
                message: 'Model name is required',
            });
            return;
        }

        if (!modelId.trim()) {
            showAppMessage({
                variant: 'error',
                timeout: 5000,
                message: 'Model ID is required',
            });
            return;
        }

        try {
            await addModel({
                pathname: `/speech/${speechId}/models/${modelId}`,
                body: {
                    name,
                    description,
                    languages,
                },
            });

            showAppMessage({
                variant: 'success',
                timeout: 5000,
                message: 'Model added successfully',
            });

            requestClose();

            navigate(`/speech/${speechId}/model/${modelId}`);
        } catch (error) {
            // Handle error
            showAppMessage({
                variant: 'error',
                timeout: 5000,
                message: 'Failed to add model',
            });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={requestClose}
            size="medium"
            title={<ModalTitle>Add Model</ModalTitle>}
            footer={(
                <div className="rainbow-flex rainbow-justify_end">
                    <Button
                        label="Cancel"
                        variant="neutral"
                        borderRadius="semi-rounded"
                        className="rainbow-m-right_medium"
                        onClick={requestClose}
                    />
                    <Button
                        label="Add"
                        variant="brand"
                        type="submit"
                        onClick={submit}
                        isLoading={isLoading}
                        disabled={isLoading}
                        borderRadius="semi-rounded"
                    />
                </div>
            )}
        >
            <div className="rainbow-p-around_medium">
                <RenderIf isTrue={isLoadingModels}>
                    <div>Loading available models...</div>
                </RenderIf>

                <RenderIf isTrue={!isLoadingModels}>
                    <Picklist
                        label="Select Model"
                        placeholder="Choose an available model"
                        value={selectedModel}
                        onChange={setSelectedModel}
                        labelAlignment="left"
                        required
                        enableSearch
                        onSearch={handleSearch}
                        className="rainbow-m-bottom_medium"
                        isLoading={isLoadingModels}
                        borderRadius="semi-rounded"
                    >
                        {filteredModels?.map((model) => (
                            <Option
                                key={model.id}
                                name={model.id}
                                label={`${model.name} (${model.id})`}
                                value={model.id}
                                searchableText={`${model.name} ${model.id}`}
                            />
                        ))}
                    </Picklist>

                    <Textarea
                        label="Description"
                        placeholder="Pick a model to see its description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="rainbow-m-bottom_medium"
                        labelAlignment="left"
                        borderRadius="semi-rounded"
                        disabled
                    />
                </RenderIf>
            </div>
        </Modal>
    );
};

export default AddModelModal;
