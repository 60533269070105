import styled, { css } from 'styled-components';
import { Badge } from 'react-rainbow-components';

// Color constants
const PURPLE_500 = '#6860D7';
const PURPLE_100 = '#F0EFFC';
const GREEN_700 = '#2E844A';
const GREEN_100 = '#EAF5EE';
const RED_600 = '#EA4444';
const RED_100 = '#FFEAEA';
const ORANGE_700 = '#D9700C';
const ORANGE_100 = '#FEF1E6';
const GREY_100 = '#F6F7F9';
const GREY_700 = '#576574';
const YELLOW_100 = '#FEF7E6';
const YELLOW_600 = '#DD7A01';

export default styled(Badge)`
    flex-shrink: 0;
    text-transform: none;
    color: ${GREY_700};
    background-color: ${GREY_100};
    border-radius: 5rem;
    text-transform: capitalize;
    font-weight: 700;
    padding: 0 0.5rem;
    line-height: 1.5rem;
    font-size: 0.625rem;
    border: none;
    min-width: 1.5rem;

    ${(props) => props.variant === 'default' && css`
        color: ${GREY_700};
        background-color: ${GREY_100};
        border: none;
    `}

    ${(props) => props.variant === 'error' && css`
        color: ${RED_600};
        background-color: ${RED_100};
        border: none;
    `}

    ${(props) => props.variant === 'success' && css`
        color: ${GREEN_700};
        background-color: ${GREEN_100};
        border: none;
    `}

    ${(props) => props.variant === 'warning' && css`
        color: ${YELLOW_600};
        background-color: ${YELLOW_100};
        border: none;
    `}

    ${(props) => props.variant === 'brand' && css`
        color: ${PURPLE_500};
        background-color: ${PURPLE_100};
        border: none;
    `}

    ${(props) => props.variant === 'inverse' && css`
        color: ${ORANGE_700};
        background-color: ${ORANGE_100};
        border: none;
    `}

    ${(props) => props.size === 'large' && css`
        height: 3rem;
        padding: 0.75rem 1.5rem;
        border: none;
    `}

    ${(props) => props.size === 'medium' && css`
        font-size: 0.625rem;
        border: none;
    `}
`;
