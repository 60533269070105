import {
    TileContainer, Label, Value, Unit,
} from './styled';

interface TileProps {
    label: string;
    value: number | string;
    unit?: string;
}

const Tile = ({ label, value, unit }: TileProps) => (
    <TileContainer>
        <Label>{label}</Label>
        <Value>
            {typeof value === 'number' ? value.toLocaleString() : value}
            {unit && <Unit>{unit}</Unit>}
        </Value>
    </TileContainer>
);

export default Tile;
