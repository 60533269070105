import React from 'react';
import { Button } from 'react-rainbow-components';
import { Price } from '../Services/types';
import TiersTable from '../TiersTable';
import {
    ServiceTitle,
    ServiceName,
    ServiceDescription,
    StyledSaraIcon,
    PriceName,
    PriceRow,
    RadioLabel,
    PriceInfo,
    StartingPrice,
} from '../Services/styled';
import {
    StyledModal,
    ModalContent,
    ModalFooter,
    CompactServiceHeader,
} from './styled';

// Fake data for testing
const mockService = {
    id: 'service-1',
    name: 'AI Assistant Pro',
    description: 'Advanced AI capabilities with enhanced features and support',
    prices: [
        {
            id: 'price-1',
            nickname: 'Basic Plan',
            price: 1000,
            tiers: [
                {
                    flat_amount: 1000,
                    flat_amount_decimal: '1000',
                    unit_amount: 50,
                    unit_amount_decimal: '50',
                    up_to: 1000,
                },
                {
                    flat_amount: null,
                    flat_amount_decimal: null,
                    unit_amount: 40,
                    unit_amount_decimal: '40',
                    up_to: 5000,
                },
                {
                    flat_amount: null,
                    flat_amount_decimal: null,
                    unit_amount: 30,
                    unit_amount_decimal: '30',
                    up_to: null,
                },
            ],
        },
        {
            id: 'price-2',
            nickname: 'Premium Plan',
            price: 5000,
            tiers: [
                {
                    flat_amount: 5000,
                    flat_amount_decimal: '5000',
                    unit_amount: 40,
                    unit_amount_decimal: '40',
                    up_to: 5000,
                },
                {
                    flat_amount: null,
                    flat_amount_decimal: null,
                    unit_amount: 30,
                    unit_amount_decimal: '30',
                    up_to: 10000,
                },
                {
                    flat_amount: null,
                    flat_amount_decimal: null,
                    unit_amount: 20,
                    unit_amount_decimal: '20',
                    up_to: null,
                },
            ],
        },
    ],
};

interface UpgradeModalProps {
    isOpen: boolean;
    onClose?: () => void;
    service?: {
        id: string;
        name: string;
        description: string;
        prices: Price[];
    };
    onPriceSelect?: (serviceId: string, price: Price | null) => void;
    selectedPrice?: Price | null;
}

const getFlatFee = (tiers: Price['tiers']) => {
    const flatFeeTier = tiers.find((tier) => tier.flat_amount !== null);
    return flatFeeTier?.flat_amount || 0;
};

const UpgradeModal: React.FC<UpgradeModalProps> = ({
    isOpen,
    onClose,
    service = mockService, // Use mock data as default
    onPriceSelect = () => {}, // Default empty function
    selectedPrice = null,
}) => {
    const sortedPrices = [...service.prices].sort(
        (a, b) => getFlatFee(a.tiers) - getFlatFee(b.tiers),
    );

    const handlePriceSelect = (price: Price) => {
        onPriceSelect(service.id, price);
    };

    return (
        <StyledModal isOpen={isOpen} onRequestClose={onClose} title="Upgrade Service">
            <CompactServiceHeader $hasContent>
                <StyledSaraIcon />
                <ServiceTitle>
                    <ServiceName>{service.name}</ServiceName>
                    <ServiceDescription>{service.description}</ServiceDescription>
                </ServiceTitle>
            </CompactServiceHeader>
            <ModalContent>
                {sortedPrices.map((price) => (
                    <div key={price.id}>
                        <PriceRow>
                            <RadioLabel>
                                <input
                                    type="radio"
                                    name={`price-${service.name}`}
                                    value={price.id}
                                    checked={selectedPrice?.id === price.id}
                                    onChange={() => handlePriceSelect(price)}
                                />
                                <PriceInfo>
                                    <PriceName>{price.nickname}</PriceName>
                                    <StartingPrice>
                                        $
                                        {(getFlatFee(price.tiers) / 100).toFixed(2)}
                                    </StartingPrice>
                                </PriceInfo>
                            </RadioLabel>
                        </PriceRow>
                        <TiersTable tiers={price.tiers} />
                    </div>
                ))}
            </ModalContent>
            <ModalFooter>
                <Button
                    variant="neutral"
                    label="Cancel"
                    onClick={onClose}
                />
                <Button
                    variant="brand"
                    label="Update"
                    onClick={() => handlePriceSelect(selectedPrice!)}
                />
            </ModalFooter>
        </StyledModal>
    );
};

export default UpgradeModal;
