import {
    PriceTableContainer, Table, TableRow, TableHeader, TableCell,
} from './styled';

type Tier = {
    up_to: number | null;
    unit_amount_decimal: string;
    flat_amount: number | null;
};

const TiersTable = ({ tiers }: { tiers: Tier[] }) => {
    if (tiers.length > 0) {
        return (
            <PriceTableContainer>
                <Table>
                    <thead>
                        <TableRow>
                            <TableHeader>Quantity</TableHeader>
                            <TableHeader>Unit Price</TableHeader>
                            <TableHeader>Flat Amount</TableHeader>
                        </TableRow>
                    </thead>
                    <tbody>
                        {tiers.map((tier) => (
                            <TableRow key={`tier-${tier.up_to ?? 'unlimited'}`}>
                                <TableCell>
                                    {tier.up_to ? `Up to ${tier.up_to.toLocaleString()}` : 'Unlimited'}
                                </TableCell>
                                <TableCell>
                                    {tier.unit_amount_decimal
                                        ? `$${(parseFloat(tier.unit_amount_decimal) / 100).toFixed(3)}`
                                        : '-'}
                                </TableCell>
                                <TableCell>
                                    {tier.flat_amount
                                        ? `$${(tier.flat_amount / 100).toFixed(2)}`
                                        : '-'}
                                </TableCell>
                            </TableRow>
                        ))}
                    </tbody>
                </Table>
            </PriceTableContainer>

        );
    }
    return null;
};

export default TiersTable;
