import { ReactNode } from 'react';
import { MarkdownOutput, RenderIf } from 'react-rainbow-components';
import { Phone } from '@rainbow-modules/icons';
import { EntityGet } from 'data/firestore/types';
import { Conversation } from 'data/firestore/session/conversation/types';
import SimpleAudioPlayer from 'components/SimpleAudioPlayer';
import Message from './message';
import getEventName from './helpers/getEventName';
import formatTimeMark from './helpers/formatTimeMark';
import { ParsedContent } from './types';
import {
    ItemContainer,
    TimeMark,
    IconContainer,
    Line,
    EventCircle,
    Content,
    Label,
    Duration,
    Circle,
    PhoneIconContainer,
    Text,
    LanguagePill,
    LanguageChangeContainer,
    StyledHelpText,
} from './styled';
import getEventDescription from './helpers/getEventDescription';
import AnswerCallMessage from './AnswerCallMessage';

const colorMap: Record<Conversation['role'], string> = {
    assistant: '#A182E0',
    user: '#F7C02B',
    event: '',
};

const getColor = ({ role, content }: {
    role: Conversation['role'];
    content: string | ParsedContent;
}) => {
    if (typeof content === 'string') {
        return colorMap[role];
    }
    if (content?.event === 'barge-in') {
        return '#CB4344';
    }
    return '';
};

const parseContent = (content: string) => {
    let parsedContent;
    try {
        parsedContent = JSON.parse(content);
    } catch (error) {
        parsedContent = content;
    }
    return parsedContent;
};

const getIcon = ({ icon, color, content }: {
    icon: ReactNode;
    color: string;
    content: string | ParsedContent;
}) => {
    if (icon) {
        return icon;
    }
    if (
        typeof content === 'object'
        && (content?.event === 'hang-up' || content?.event === 'answer-call')
    ) {
        return (
            <PhoneIconContainer>
                <Phone />
            </PhoneIconContainer>
        );
    }
    return <EventCircle color={color}><Circle color={color} /></EventCircle>;
};

interface Props {
    icon?: ReactNode;
    nextTimeMark?: number;
    isLastItem?: boolean;
    isSelected?: boolean;
    label?: string;
    data?: EntityGet<Conversation>;
    onClick?: () => void;
}

const eventsWithText = [
    'final-transcript',
    'synthesize-llm-response',
    'partial-transcript',
];

const Event = (props: Props) => {
    const {
        icon,
        nextTimeMark = 0,
        isLastItem,
        isSelected,
        label,
        data,
        onClick = () => {},
    } = props;
    const { timeMark = 0, role, content } = data || {} as EntityGet<Conversation>;
    const parsedContent = parseContent(content);
    const color = getColor({ role, content: parsedContent });
    const eventIcon = getIcon({ icon, color, content: parsedContent });
    const eventName = label || getEventName({ role, content: parsedContent });
    return (
        <ItemContainer
            isSelected={isSelected}
            onClick={data && onClick}
        >
            {typeof timeMark === 'number' ? (
                <TimeMark>
                    {formatTimeMark(timeMark)}
                </TimeMark>
            ) : null}
            <Line color={color} isLastItem={isLastItem}>
                <IconContainer color={color}>{eventIcon}</IconContainer>
            </Line>
            <Content>
                <Label>
                    {eventName}
                    {' '}
                    <StyledHelpText
                        title={eventName}
                        text={(
                            <MarkdownOutput
                                value={getEventDescription({ role, content: parsedContent })}
                            />
                        )}
                    />
                </Label>
                {/* assistant and user messages */}
                <RenderIf isTrue={role === 'assistant' || role === 'user'}>
                    <Message data={data as EntityGet<Conversation>} />
                </RenderIf>
                {/* event with text */}
                <RenderIf isTrue={role === 'event' && eventsWithText.includes(parsedContent?.event)}>
                    <Text>{parsedContent?.data?.text}</Text>
                </RenderIf>
                {/* event with language change */}
                <RenderIf isTrue={role === 'event' && parsedContent?.event === 'change-language'}>
                    <LanguageChangeContainer>
                        Changed language from
                        {' '}
                        <LanguagePill>{parsedContent?.data?.previousLanguage}</LanguagePill>
                        {' '}
                        to
                        {' '}
                        <LanguagePill>{parsedContent?.data?.language}</LanguagePill>
                    </LanguageChangeContainer>
                </RenderIf>
                {/* answer call event */}
                <RenderIf isTrue={role === 'event' && parsedContent?.event === 'answer-call'}>
                    <AnswerCallMessage content={parsedContent} />
                </RenderIf>
                {/* audio player */}
                <RenderIf isTrue={role === 'event' && parsedContent?.event === 'user-end-speak'}>
                    <SimpleAudioPlayer audioBase64={parsedContent?.data?.audio} />
                </RenderIf>
                {/* duration */}
                <RenderIf isTrue={!isLastItem}>
                    <Duration>{`${(nextTimeMark - timeMark).toFixed(0)} ms`}</Duration>
                </RenderIf>
            </Content>
        </ItemContainer>
    );
};

export default Event;
