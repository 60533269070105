import styled from 'styled-components';

export const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
`;

export const SectionTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
`;

export const SectionDescription = styled.p`
    font-size: 0.875rem;
    line-height: 1.5;
    color: #576574;
    margin: 0;
`;
