import { useState } from 'react';
import { CheckboxToggle as Toggle } from 'react-rainbow-components';
import {
    ServiceContainer, ServiceHeader, ServiceTitle,
    ServiceName, ServiceDescription, CardContent,
    PriceContainer, PriceName, PriceRow,
    RadioLabel,
    StyledSaraIcon,
    CardFooter,
    FooterText,
    PriceInfo,
    StartingPrice,
} from './styled';
import { ServiceCardProps, Price } from './types';
import TiersTable from '../TiersTable';

const getFlatFee = (tiers: Price['tiers']) => {
    const flatFeeTier = tiers.find((tier) => tier.flat_amount !== null);
    return flatFeeTier?.flat_amount || 0;
};

const ServiceCard = ({
    service,
    onPriceSelect,
    selectedPrice,
}: ServiceCardProps) => {
    const [isEnabled, setIsEnabled] = useState(Boolean(selectedPrice));

    // Sort prices by flat fee
    const sortedPrices = [...service.prices].sort(
        (a, b) => getFlatFee(a.tiers) - getFlatFee(b.tiers),
    );

    const handlePriceSelect = (price: Price) => {
        if (isEnabled) {
            onPriceSelect(service.id, price);
        }
    };

    const handleToggle = (event: { target: { checked: boolean } }) => {
        setIsEnabled(event.target.checked);
        if (!event.target.checked) {
            onPriceSelect(service.id, null);
        }
    };

    if (!service) {
        return null;
    }

    return (
        <ServiceContainer $hasContent={isEnabled}>
            <ServiceHeader $hasContent={isEnabled}>
                <StyledSaraIcon />
                <ServiceTitle>
                    <ServiceName>{service.name}</ServiceName>
                    <ServiceDescription>{service.description}</ServiceDescription>
                </ServiceTitle>
            </ServiceHeader>
            <CardContent $isVisible={isEnabled}>
                {sortedPrices.map((price) => (
                    <div key={price.id}>
                        <PriceRow>
                            <RadioLabel disabled={!isEnabled}>
                                <input
                                    type="radio"
                                    name={`price-${service.name}`}
                                    value={price.id}
                                    checked={selectedPrice?.id === price.id}
                                    onChange={() => handlePriceSelect(price)}
                                    disabled={!isEnabled}
                                />
                                <PriceContainer>
                                    <PriceInfo>
                                        <PriceName>{price.nickname}</PriceName>
                                        <StartingPrice>
                                            $
                                            {(getFlatFee(price.tiers) / 100).toFixed(2)}
                                        </StartingPrice>
                                    </PriceInfo>
                                </PriceContainer>
                            </RadioLabel>
                        </PriceRow>
                        <TiersTable tiers={price.tiers} />
                    </div>
                ))}
            </CardContent>
            <CardFooter>
                <FooterText>Service Status</FooterText>
                <Toggle
                    id={`toggle-${service.id}`}
                    label="Enable"
                    value={isEnabled}
                    onChange={handleToggle}
                />
            </CardFooter>
        </ServiceContainer>
    );
};

export default ServiceCard;
