import React from 'react';
import {
    Container, ProcessName, FieldValue, FieldLabel, FieldContainer,
} from './styled';
import { Region } from '../types';

const RegionDetails = (props: Partial<Region>) => {
    const {
        role, start, end, content,
    } = props;

    // Format duration in milliseconds and round numbers for cleaner display
    const duration = `${Math.round((end as number - (start as number)) * 1000)} ms`;
    const startTime = Math.round((start as number) * 100) / 100;
    const endTime = Math.round((end as number) * 100) / 100;

    // Get confidence class for styling
    const getConfidenceClass = (confidenceValue?: number) => {
        if (!confidenceValue) return '';
        if (confidenceValue >= 0.8) return 'confidence-high';
        if (confidenceValue >= 0.5) return 'confidence-medium';
        return 'confidence-low';
    };

    const formatContent = (contentValue: any) => {
        if (!contentValue) return 'N/A';
        if (typeof contentValue === 'string') return contentValue;
        return JSON.stringify(contentValue);
    };

    const renderCustomField = (label: string, value: any, key: string) => {
        const isConfidence = label.toLowerCase() === 'confidence';
        const confidenceValue = isConfidence && typeof value === 'number' ? value : undefined;
        const displayValue = isConfidence && confidenceValue !== undefined
            ? `${Math.round(confidenceValue * 100)}%`
            : formatContent(value);

        return (
            <FieldContainer key={key}>
                <FieldLabel>{label}</FieldLabel>
                <FieldValue className={isConfidence ? getConfidenceClass(confidenceValue) : ''}>
                    {displayValue}
                </FieldValue>
            </FieldContainer>
        );
    };

    const dynamicContent = role === 'final-transcript'
        ? [
            { label: 'Transcript', value: content?.transcript },
            { label: 'Confidence', value: content?.confidence },
        ] : [
            { label: 'Content', value: content },
        ];

    return (
        <Container>
            <ProcessName role={role as string}>{role}</ProcessName>
            <div>
                {renderCustomField('Start', startTime, 'start')}
                {renderCustomField('End', endTime, 'end')}
                {renderCustomField('Duration', duration, 'duration')}
                {dynamicContent.map((item) => (
                    renderCustomField(item.label, item.value, item.label)
                ))}
            </div>
        </Container>
    );
};

export default RegionDetails;
