import { CellWrapper } from '../styled';

interface Props {
    value?: number;
}

const priceFormatter = (currency: 'usd' | 'eur' | 'gbp') => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency.toUpperCase(),
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const Total = ({ value }: Props) => (
    <CellWrapper>
        {priceFormatter('usd').format((value || 0) / 100)}
    </CellWrapper>
);

export default Total;
