import Mic from 'components/icons/mic';
import Phone from 'components/icons/phone';
import User from 'components/icons/user';
import Voice from 'components/icons/voice';
import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 0;
    gap: 8px;
`;

export const Pill = styled.div`
    display: flex;
    align-items: center;
    background-color: rgba(161, 130, 224, 0.1);
    border-radius: 20px;
    padding: 6px;
    gap: 6px;
    border: 1px solid rgba(161, 130, 224, 0.2);
    box-shadow: 0 1px 2px rgba(161, 130, 224, 0.1);
    margin-right: 12px;
    flex-shrink: 1;
    white-space: nowrap;
    width: auto;
    max-width: fit-content;
`;

export const Label = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: ${color('text.main')};
    margin-right: 8px;
`;

export const Field = styled.span`
    font-size: 12px;
    color: ${color('text.label')};
    background-color: rgba(161, 130, 224, 0.15);
    padding: 3px 8px;
    border-radius: 12px;
    font-family: monospace;
    font-weight: 500;
    letter-spacing: 0.3px;
    box-shadow: 0 1px 2px rgba(161, 130, 224, 0.15);
    transition: all 0.2s ease;
    
    &:hover {
        background-color: rgba(161, 130, 224, 0.25);
    }
`;

export const UserIcon = styled(User)`
    width: 16px;
    height: 16px;
`;

export const PhoneIcon = styled(Phone)`
    width: 16px;
    height: 16px;
`;

export const VoiceIcon = styled(Voice)`
    width: 16px;
    height: 16px;
    fill: white;
`;

export const MicIcon = styled(Mic)`
    width: 16px;
    height: 16px;
    fill: white;
`;
