/* eslint-disable no-debugger */
/* eslint-disable react/forbid-dom-props */
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
import { Slider, InternalOverlay } from 'react-rainbow-components';
import {
    useEffect, useRef, useState, useCallback,
} from 'react';
import { Play, Pause } from 'components/icons';
import {
    Container, Header, Wave, WaveformGlobalStyle, PlayButton, Timer, Zoom, Controls,
} from './styled';
import RegionDetails from './RegionDetails';
import { ConversationPlayerProps, Region } from './types';

const useWavesurfer = (
    containerRef: React.RefObject<HTMLDivElement>,
    url?: string,
) => {
    const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);

    useEffect(() => {
        if (containerRef.current && url) {
            const topTimeline = TimelinePlugin.create({
                height: 140,
                insertPosition: 'beforebegin',
                timeInterval: 5,
                primaryLabelInterval: 30,
                secondaryLabelInterval: 10,
            });
            const ws = WaveSurfer.create({
                url,
                barWidth: 1,
                barGap: 2,
                barRadius: 2,
                cursorWidth: 2,
                height: 60,
                progressColor: 'rgb(161, 130, 224)',
                waveColor: 'rgb(200, 200, 200)',
                container: containerRef.current,
                plugins: [topTimeline],
            });

            setWavesurfer(ws);

            return () => ws.destroy();
        }
        return undefined;
    }, [url, containerRef]);

    return wavesurfer;
};

const useRegions = (wavesurfer: WaveSurfer | null) => {
    const [regions, setRegions] = useState<RegionsPlugin>();

    useEffect(() => {
        if (wavesurfer) {
            setRegions(wavesurfer.registerPlugin(RegionsPlugin.create()));
        }
        return undefined;
    }, [wavesurfer]);

    return regions;
};

const useDuration = (wavesurfer: WaveSurfer | null) => {
    const [duration, setDuration] = useState('0:00');

    useEffect(() => {
        if (wavesurfer) {
            wavesurfer.on('ready', () => {
                setDuration(wavesurfer.getDuration().toFixed(2));
            });
        }
    }, [wavesurfer]);

    return duration;
};

const ConversationPlayer = (props: ConversationPlayerProps) => {
    const { url, conversation = [] } = props;
    const waveRef = useRef<HTMLDivElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [zoom, setZoom] = useState(0);
    const regionRef = useRef<HTMLElement | null>(null);
    const [regionDetails, setRegionDetails] = useState<Region | undefined>();

    const wavesurfer = useWavesurfer(
        waveRef,
        url,
    );
    const duration = useDuration(wavesurfer);
    const regions = useRegions(wavesurfer);

    useEffect(() => {
        if (regions) {
            regions.clearRegions();
            conversation.forEach((item) => {
                if (regions) {
                    const region = regions.addRegion({
                        start: item.start,
                        end: item.end,
                        drag: false,
                        resize: false,
                        id: item.role,
                    });
                    region.element.addEventListener('mouseenter', () => {
                        regionRef.current = region.element;
                        if (item.role === 'final-transcript') {
                            setRegionDetails({
                                role: item.role,
                                start: item.start,
                                end: item.end,
                                content: {
                                    confidence: item.content?.confidence,
                                    transcript: item.content?.transcript,
                                },
                            });
                        } else {
                            setRegionDetails({
                                role: item.role,
                                start: item.start,
                                end: item.end,
                                content: item.content,
                            });
                        }
                    });
                    region.element.addEventListener('mouseleave', () => {
                        regionRef.current = null;
                        setRegionDetails(undefined);
                    });
                }
            });
        }
    }, [regions, conversation]);

    const onPlayClick = useCallback(() => {
        if (wavesurfer) {
            if (wavesurfer.isPlaying()) {
                wavesurfer.pause();
            } else {
                wavesurfer.play();
            }
        }
    }, [wavesurfer]);

    useEffect(() => {
        if (wavesurfer) {
            setCurrentTime(0);
            setIsPlaying(false);

            const subscriptions = [
                wavesurfer.on('play', () => setIsPlaying(true)),
                wavesurfer.on('pause', () => setIsPlaying(false)),
                wavesurfer.on('audioprocess', (time: number) => setCurrentTime(time)),
            ];

            return () => {
                subscriptions.forEach((unsub) => unsub());
            };
        }
        return undefined;
    }, [wavesurfer]);
    const updateZoom = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (wavesurfer) {
            const nextZoom = Number(event.target.value);
            setZoom(nextZoom);
            wavesurfer.zoom(nextZoom);
        }
    };

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <Container>
            <Header>
                <Controls>
                    <PlayButton onClick={onPlayClick} variant="brand" size="small" icon={isPlaying ? <Pause /> : <Play />} />
                    <Timer>
                        {formatTime(currentTime)}
                        {' '}
                        /
                        {' '}
                        {formatTime(parseFloat(duration))}
                    </Timer>
                </Controls>
                <Zoom htmlFor="zoom">
                    Zoom:
                    {' '}
                    <Slider label="Zoom" hideLabel min="0" max="100" value={zoom} onChange={updateZoom} />
                </Zoom>
            </Header>
            <WaveformGlobalStyle />
            <Wave ref={waveRef} id="wave" />
            <InternalOverlay
                isVisible={!!regionDetails}
                render={() => (
                    <RegionDetails
                        {...regionDetails}
                    />
                )}
                triggerElementRef={() => regionRef}
            />
        </Container>
    );
};

export default ConversationPlayer;
