import { Invoice } from '../types';
import { CellWrapper, StyledLink } from '../styled';

interface Props {
    value?: string;
    row?: Invoice;
}

const Id = ({ value, row }: Props) => {
    if (!value) return null;
    return (
        <CellWrapper>
            <StyledLink to={row?.url as string} target="_blank" rel="noopener noreferrer">
                {value}
            </StyledLink>
        </CellWrapper>
    );
};

export default Id;
