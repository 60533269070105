import { Agent } from 'data/firestore/agent/types';
import { CheckCircleFilled, ErrorCircleFilled as CancelIcon } from '@rainbow-modules/icons';
import {
    SubscriptionContainer,
    SubscriptionGrid,
    StyledRecordField,
    StatusBadge,
} from './styled';

const getStatusDisplay = (status: string) => {
    let icon = null;
    if (status === 'active') {
        icon = <CheckCircleFilled />;
    } else if (['canceled', 'unpaid', 'past_due', 'incomplete', 'incomplete_expired'].includes(status)) {
        icon = <CancelIcon />;
    }

    return (
        <StatusBadge status={status}>
            {icon}
            {status.charAt(0).toUpperCase() + status.slice(1).replace(/_/g, ' ')}
        </StatusBadge>
    );
};

const SubscriptionDetails = ({
    agentData,
    isLoading,
}:{
    agentData?: Agent | null;
    isLoading?: boolean;
}) => (
    <SubscriptionContainer>
        <SubscriptionGrid>
            <StyledRecordField
                isLoading={isLoading}
                label="Subscription Status"
                value={agentData?.billing?.subscriptionStatus
                    ? getStatusDisplay(agentData.billing.subscriptionStatus)
                    : '-'}
            />
            <StyledRecordField
                isLoading={isLoading}
                label="Subscription ID"
                value={agentData?.billing?.subscriptionId || '-'}
            />
            <StyledRecordField
                isLoading={isLoading}
                label="Customer ID"
                value={agentData?.billing?.customerId || '-'}
            />
        </SubscriptionGrid>
    </SubscriptionContainer>
);

export default SubscriptionDetails;
