import styled from 'styled-components';
import { Table, TableWithBrowserPagination } from 'react-rainbow-components';
import { WHITE } from '../../constants';

export default styled(Table)`
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    border: 2px solid #e1e1e1;
    border-radius: 0.75rem;

    table {
        padding-bottom: 1.5rem;

        thead {
            background-color: #f8f9fa;

            th {
                font-weight: 500;
                font-size: 0.8125rem;
                letter-spacing: 0.0625rem;
            
                div {
                    background: transparent;
                }
            }

            tr:first-child th:first-child {
                border-top-left-radius: 0.5rem;
            }

            tr:first-child th:last-child {
                border-top-right-radius: 0.5rem;
            }
        }
    }
`;

export const TableWithPagination = styled(TableWithBrowserPagination)`
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    overflow: auto;
    box-sizing: border-box;
    border: 1px solid #e1e1e1;
    border-radius: 0.5rem;

    table {
        padding-bottom: 1.5rem;

        thead {
            background-color: #f8f9fa;

            th {
                font-weight: 500;
                font-size: 0.8125rem;
                letter-spacing: 0.0625rem;
            
                div {
                    background: transparent;
                }
            }

            tr:first-child th:first-child {
                border-top-left-radius: 0.5rem;
            }

            tr:first-child th:last-child {
                border-top-right-radius: 0.5rem;
            }
        }
    }

    & > div:last-child {
        background: ${WHITE};
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }
`;
