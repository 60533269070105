import { Avatar, Spinner, Button } from 'react-rainbow-components';
import useHttpQuery from 'data/firestore/useHttpQuery';
import { useParams } from 'react-router-dom';
import { confirmModal } from '@rainbow-modules/app';
import WarningIcon from 'components/icons/warning';
import { useState } from 'react';
import SaraAvatar from '../../../../../../components/icons/sara';
import {
    ServicesContainer,
    ServiceCard,
    ServiceHeader,
    ServiceInfo,
    ServiceName,
    ServiceDescription,
    ServicesWrapper,
    CardActions,
    AddServiceCard,
    AddServiceIcon,
    AddServiceButton,
    HeaderContainer,
    HeaderText,
    QuestionContainer,
    QuestionText,
    ExplanationText,
} from './styled';
import TiersTable from '../TiersTable';
import UpgradeModal from './UpgradeModal';

type Tier = {
    flat_amount: number | null;
    flat_amount_decimal: string | null;
    unit_amount: number | null;
    unit_amount_decimal: string;
    up_to: number | null;
};

type Item = {
    id: string;
    service: {
        name: string;
        description: string;
    };
    tiers: Tier[];
};

const ActiveServices = () => {
    const { agentId } = useParams();
    const {
        data,
        isLoading,
    } = useHttpQuery<{ items: Array<Item> }>({
        pathname: `/agents/${agentId}/subscription`,
        method: 'GET',
        server: process.env.REACT_APP_BASE_URL,
    });

    // Add state for modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    const removeService = async () => {
        if (await confirmModal({
            header: (
                <HeaderContainer>
                    <WarningIcon />
                    <HeaderText>Remove Service</HeaderText>
                </HeaderContainer>
            ),
            question: (
                <QuestionContainer>
                    <QuestionText>
                        Are you sure you want to remove this service
                        from the customer&apos;s subscription?
                    </QuestionText>
                    <ExplanationText>
                        This action will immediately stop the service and the customer will no
                        longer be billed for it. Any existing data or configurations will be
                        preserved, but the customer won&apos;t be able to use the service
                        until resubscribed.
                    </ExplanationText>
                </QuestionContainer>
            ),
            okButtonLabel: 'Remove',
            borderRadius: 'semi-rounded',
        })) {
            // eslint-disable-next-line no-alert
            alert('// TODO: Implement remove service logic');
        }
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <ServicesWrapper>
            <ServicesContainer>
                {data?.items?.map((item) => (
                    <ServiceCard key={item.id}>
                        <ServiceHeader $hasContent>
                            <Avatar
                                icon={<SaraAvatar />}
                                size="large"
                            />
                            <ServiceInfo>
                                <ServiceName>{item.service.name}</ServiceName>
                                <ServiceDescription>{item.service.description}</ServiceDescription>
                            </ServiceInfo>
                        </ServiceHeader>
                        <TiersTable tiers={item.tiers} />
                        <CardActions>
                            <Button
                                variant="neutral"
                                label="Remove"
                                onClick={removeService}
                            />
                            <Button
                                variant="brand"
                                label="Upgrade"
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            />
                        </CardActions>
                    </ServiceCard>
                ))}
                <AddServiceButton
                    onClick={() => {
                        // eslint-disable-next-line no-alert
                        alert('// TODO: Implement add service logic');
                    }}
                >
                    <AddServiceCard>
                        <AddServiceIcon>+</AddServiceIcon>
                        <ServiceName>Add New Service</ServiceName>
                        <ServiceDescription>
                            Subscribe to additional services for your agent
                        </ServiceDescription>
                    </AddServiceCard>
                </AddServiceButton>
            </ServicesContainer>
            <UpgradeModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </ServicesWrapper>
    );
};

export default ActiveServices;
