import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Table, Column, RenderIf, Input,
    Button, ButtonIcon,
} from 'react-rainbow-components';
import { Trash } from 'components/icons';
import { MagnifyingGlass, Plus } from '@rainbow-modules/icons';
import useVoices from 'data/firestore/speech/voices/useCollection';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { confirmModal, showAppMessage } from '@rainbow-modules/app';
import {
    Header, StyledNavigationButtonColumn, Actions,
} from '../styled';
import AddVoiceModal from './addVoiceModal';

const DeleteVoiceButton = ({ row, onDelete }: { row?: any; onDelete: (id: string) => void }) => (
    <ButtonIcon
        icon={<Trash />}
        borderRadius="semi-rounded"
        onClick={(e) => {
            e.stopPropagation();
            if (row) onDelete(row.id);
        }}
    />
);

const Voices = () => {
    const { speechId = '' } = useParams();
    const { data, isLoading } = useVoices(speechId as string);
    const navigate = useNavigate();
    const navigateToVoice = (value: any) => {
        navigate(`/speech/${speechId}/voice/${value.row.id}`);
    };
    const [isOpenAddVoiceModal, setIsOpenAddVoiceModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const hasVoices = data?.length > 0;

    // Filter voices based on search term
    const filteredData = data?.filter(
        (voice) => voice.name.toLowerCase().includes(searchTerm.toLowerCase()),
    ) || [];

    const { mutateAsync: deleteVoice, isLoading: isDeleting } = useHttpMutation({
        method: 'DELETE',
    });

    const handleDeleteVoice = async (voiceId: string) => {
        const confirmed = await confirmModal({
            header: 'Delete Voice',
            question: 'Are you sure you want to delete this voice?',
            variant: 'destructive',
            okButtonLabel: 'Delete',
            cancelButtonLabel: 'Cancel',
        });

        if (confirmed) {
            try {
                await deleteVoice({
                    pathname: `/speech/${speechId}/voices/${voiceId}`,
                });
                showAppMessage({
                    variant: 'success',
                    timeout: 5000,
                    message: 'Voice deleted successfully',
                });
            } catch (error) {
                showAppMessage({
                    variant: 'error',
                    timeout: 5000,
                    message: 'Failed to delete voice',
                });
            }
        }
    };

    return (
        <>
            <Header>
                <Input
                    size="small"
                    borderRadius="semi-rounded"
                    placeholder="Find a voice..."
                    icon={<MagnifyingGlass />}
                    type="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                <Actions>
                    <Button
                        variant="brand"
                        size="small"
                        borderRadius="semi-rounded"
                        onClick={() => setIsOpenAddVoiceModal(true)}
                    >
                        <Plus className="rainbow-m-right_small" />
                        Add Voice
                    </Button>
                </Actions>
            </Header>
            <RenderIf isTrue={isLoading || hasVoices}>
                <Table
                    data={filteredData}
                    isLoading={isLoading || isDeleting}
                    keyField="id"
                    variant="listview"
                    className="rainbow-m-left_large rainbow-m-right_large"
                >
                    <Column
                        header="Voice"
                        field="name"
                        headerAlignment="left"
                        cellAlignment="left"
                        component={StyledNavigationButtonColumn}
                        onClick={navigateToVoice}
                    />
                    <Column
                        width={100}
                        headerAlignment="center"
                        cellAlignment="center"
                        component={DeleteVoiceButton}
                        onDelete={handleDeleteVoice}
                    />
                </Table>
            </RenderIf>
            <AddVoiceModal
                isOpen={isOpenAddVoiceModal}
                onRequestClose={() => setIsOpenAddVoiceModal(false)}
                speechId={speechId}
                existingVoicesIds={data?.map((voice) => voice.id) || []}
            />
        </>
    );
};

export default Voices;
