import { ButtonIcon } from 'react-rainbow-components';
import styled, { createGlobalStyle } from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    border: 1px solid rgba(215, 217, 226, 0.6);
    transition: all 0.2s ease-in-out;
    
    &:hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    }
`;

export const WaveformGlobalStyle = createGlobalStyle`
    #wave ::part(scroll) {
        height: 100px;
    }

    #wave ::part(cursor) {
        height: 140px;
        border-radius: 0;
        background-color: rgba(161, 130, 224, 0.3);
    }

    #wave ::part(region) {
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;     
        overflow: hidden;         
        text-overflow: ellipsis;
        padding: 0 8px;   
    }

    #wave ::part(assistant) {
        background-color: rgba(127, 103, 190, 0.3) !important;
        top: 50px;
        height: 5px;
    }
    
    #wave ::part(user) {
        background-color: rgba(247, 192, 43, 0.3) !important;
        top: 50px;
        height: 5px;
    }

    #wave ::part(synthesize) {
        background-color: rgba(21, 101, 192, 0.3) !important;
        top: 70px;
        height: 5px;
    }
    #wave ::part(agent-call) {
        background-color: rgba(46, 125, 50, 0.3) !important;
        top: 60px;
        height: 5px;
    }
    #wave ::part(final-transcript) {
        background-color: rgba(247, 192, 43, 0.5) !important;
        top: 60px;
        height: 5px;        
    }

    #wave ::part(barge-in) {
        background-color: rgba(203, 67, 68, 0.7) !important;
        top: 70px;
        height: 5px;        
    }

    #wave ::part(region-content) {
        padding: 0 !important;
        margin: 0 !important;
        white-space: nowrap;     
        overflow: hidden;         
        text-overflow: ellipsis;
        font-family: Inter;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    #wave ::part(timeline-notch) {
        color: #979797;
        text-align: center;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

export const Header = styled.div`
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 12px;
    background-color: #fff;
`;

export const PlayButton = styled(ButtonIcon)`
    min-width: 28px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Timer = styled.div`
    font-size: 12px;
    color: ${color('text.label')};
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.01em;
    padding: 0 4px;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    transition: all 0.2s ease;
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

export const Controls = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const Zoom = styled.label`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: ${color('text.label')};
    max-width: 200px;
    padding: 4px 8px;
    border-radius: 4px;
    transition: all 0.2s ease;
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

export const Wave = styled.div`
    min-height: 80px;
    background-color: #F9F9F9;
`;
