import styled from 'styled-components';
import { Card, Modal } from 'react-rainbow-components';
import {
    ServiceHeader,
    ServiceTitle,
    ServiceName,
    ServiceDescription,
    StyledSaraIcon,
} from '../Services/styled';

// Re-export components from Services/styled
export {
    ServiceHeader,
    ServiceTitle,
    ServiceName,
    ServiceDescription,
    StyledSaraIcon,
};

export const ServicesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
`;

export const ServiceCard = styled(Card)`
    padding: 1.5rem;
    width: 400px;
    flex: 0 0 400px;
`;

export const ManageSubscriptionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
`;

export const ServicesWrapper = styled.div`
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 0.875rem;
`;

export const CardActions = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #e1e1e1;
`;

export const AddServiceButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    width: 400px;
    flex: 0 0 400px;
    cursor: pointer;
    text-align: left;

    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: 2px solid #01b6f5;
        border-radius: 0.875rem;
    }
`;

export const AddServiceCard = styled(ServiceCard)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: 2px dashed #e1e1e1;
    background-color: #fafafa;

    ${AddServiceButton}:hover & {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    ${ServiceName} {
        margin-top: 1rem;
    }

    ${ServiceDescription} {
        max-width: 80%;
    }
`;

export const AddServiceIcon = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #e1e1e1;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 300;
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    padding: 1rem 0;
`;

export const HeaderText = styled.h2`
    font-size: 24px;
    color: #2A3039;
    margin: 0;
`;

export const QuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 300px;
    padding: 1.5rem;
    text-align: center;
    font-size: 18px;
    line-height: 1.5;
    color: #2A3039;
    box-sizing: border-box;
    margin: 0 auto;
`;

export const QuestionText = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1rem;
`;

export const ExplanationText = styled.div`
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color: #666;
    line-height: 1.6;
`;

export const StyledModal = styled(Modal)`
    padding: 0;
    min-width: 600px;

    > div:first-child {
        padding: 0;
        display: flex;
        flex-direction: column;
        max-height: 85vh;
    }
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    overflow-y: auto;
    flex: 1;
`;

export const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 0.5rem;
    border-top: 1px solid #e2e8f0;
    background: white;
    position: sticky;
    bottom: 0;
    margin: 0 -1px -1px -1px;
`;

export const CompactServiceHeader = styled(ServiceHeader)`
    padding: 0.75rem 1rem;
    gap: 1rem;
    border-bottom: 1px solid #e2e8f0;
    margin-bottom: 0;
    width: 100%;
    align-items: center;
    
    ${StyledSaraIcon} {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
    }

    ${ServiceTitle} {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 48px;
    }

    ${ServiceName} {
        font-size: 1.1rem;
        margin-bottom: 0.125rem;
        line-height: 1.2;
    }

    ${ServiceDescription} {
        font-size: 0.813rem;
        line-height: 1.2;
        color: #64748b;
    }
`;

export const ServiceInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
