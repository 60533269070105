/* eslint-disable jsx-a11y/media-has-caption */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useHttpMutation from 'data/firestore/useHttpMutation';
import useHttpQuery from 'data/firestore/useHttpQuery';
import {
    Modal, Button, Picklist, Option,
    RenderIf,
} from 'react-rainbow-components';
import { showAppMessage } from '@rainbow-modules/app';
import { ModalTitle } from '../styled';

interface AddVoiceModalProps {
    onRequestClose: () => void;
    isOpen: boolean;
    speechId: string;
    existingVoicesIds?: string[];
}

interface Voice {
    id: string;
    name: string;
    description: string;
    labels: Record<string, string>;
    previewUrl?: string;
}

interface PicklistValue {
    label?: string;
    name?: string | number;
    value?: any;
}

const AddVoiceModal = (props: AddVoiceModalProps) => {
    const {
        isOpen, onRequestClose, speechId, existingVoicesIds,
    } = props;
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [selectedVoice, setSelectedVoice] = useState<PicklistValue>();
    const [filteredVoices, setFilteredVoices] = useState<Voice[]>([]);
    const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);

    const { data: availableVoices, isLoading: isLoadingVoices } = useHttpQuery<{
        data: Voice[]
    }>({
        pathname: `/speech/${speechId}/available-voices`,
        method: 'GET',
    });

    useEffect(() => {
        if (availableVoices?.data) {
            const filtered = availableVoices.data.filter(
                (voice) => !(existingVoicesIds || []).includes(voice.id),
            );
            setFilteredVoices(filtered);
        }
    }, [availableVoices, existingVoicesIds]);

    useEffect(() => {
        if (selectedVoice && selectedVoice.value) {
            const voice = availableVoices?.data.find((v) => v.id === selectedVoice.value);
            if (voice) {
                setName(voice.name);
                setId(voice.id);
                setPreviewUrl(voice.previewUrl);
            }
        }
    }, [availableVoices?.data, selectedVoice]);

    const { mutateAsync: addVoice, isLoading } = useHttpMutation({
        method: 'PUT',
    });

    const handleSearch = (searchText: string) => {
        const filtered = availableVoices?.data.filter((voice) => {
            const searchableText = `${voice.name} (${Object.values(voice.labels).join(', ')})`.toLowerCase();
            const search = searchText.toLowerCase();
            return searchableText.includes(search);
        }).filter(
            (voice) => !(existingVoicesIds || []).includes(voice.id),
        );
        setFilteredVoices(filtered || []);
    };

    const requestClose = () => {
        setName('');
        setId('');
        setPreviewUrl(undefined);
        setSelectedVoice(undefined);
        onRequestClose();
    };

    const submit = async () => {
        if (!name.trim()) {
            showAppMessage({
                variant: 'error',
                timeout: 5000,
                message: 'Voice name is required',
            });
            return;
        }

        if (!id) {
            showAppMessage({
                variant: 'error',
                timeout: 5000,
                message: 'Please select a voice',
            });
            return;
        }

        try {
            await addVoice({
                pathname: `/speech/${speechId}/voices/${id}`,
                body: {
                    name,
                },
            });
            requestClose();
            navigate(`/speech/${speechId}/voice/${id}`);
            showAppMessage({
                variant: 'success',
                timeout: 5000,
                message: 'Voice created successfully',
            });
        } catch (error) {
            showAppMessage({
                variant: 'error',
                timeout: 5000,
                message: 'Failed to create voice',
            });
        }
    };

    return (
        <Modal
            title={<ModalTitle>Add New Voice</ModalTitle>}
            size="medium"
            isOpen={isOpen}
            onRequestClose={requestClose}
            borderRadius="semi-rounded"
            footer={(
                <div className="rainbow-flex rainbow-justify_end">
                    <Button
                        label="Cancel"
                        variant="neutral"
                        borderRadius="semi-rounded"
                        className="rainbow-m-right_medium"
                        onClick={requestClose}
                    />
                    <Button
                        label="Add"
                        variant="brand"
                        type="submit"
                        onClick={submit}
                        isLoading={isLoading}
                        disabled={isLoading}
                        borderRadius="semi-rounded"
                    />
                </div>
            )}
        >
            <div className="rainbow-p-around_medium">
                <Picklist
                    label="Select Voice"
                    placeholder="Choose an available voice"
                    value={selectedVoice}
                    onChange={setSelectedVoice}
                    labelAlignment="left"
                    required
                    enableSearch
                    onSearch={handleSearch}
                    className="rainbow-m-bottom_medium"
                    isLoading={isLoadingVoices}
                    borderRadius="semi-rounded"
                >
                    {filteredVoices?.map((voice) => (
                        <Option
                            key={voice.id}
                            name={voice.id}
                            label={`${voice.name} (${Object.values(voice.labels).join(', ')})`}
                            value={voice.id}
                            searchableText={`${voice.name} (${Object.values(voice.labels).join(', ')})`}
                        />
                    ))}
                </Picklist>
                <RenderIf isTrue={previewUrl}>
                    <audio
                        controls
                        className="rainbow-m-top_medium"
                        src={previewUrl}
                    >
                        Your browser does not support the audio element.
                    </audio>
                </RenderIf>
            </div>
        </Modal>
    );
};

export default AddVoiceModal;
