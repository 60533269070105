import { RainbowFirebaseApp } from '@rainbow-modules/app';
import {
    Routes,
    Route,
    Outlet,
    Navigate,
} from 'react-router-dom';
import { app } from 'data/firebase';
import { WhenAuthenticated, WhenNoAuthenticated } from '@rainbow-modules/auth';
import Login from 'pages/Login';
import AppLayout from 'layouts/App';
import DatasetLayout from 'layouts/Dataset';
import Dataset from 'pages/App/Dataset';
import Conversation from 'pages/App/Dataset/Conversation';
import BuildingBlocks from 'pages/App/Dataset/BuildingBlocks';
import DatasetGroups from 'pages/App/Dataset/Groups';
import DatasetSettings from 'pages/App/Dataset/Settings';
import GroupDetails from 'pages/App/Dataset/GroupDetails';
import History from 'pages/App/History';
import HistoryConversationsList from 'pages/App/History/ConversationsList';
import HistoryConversationsDetailsLayout from 'pages/App/History/ConversationDetails';
import HistoryConversationDebug from 'pages/App/History/ConversationDetails/Debug';
import HistoryConversationTranscript from 'pages/App/History/ConversationDetails/Transcript';
import HistoryConversationDetails from 'pages/App/History/ConversationDetails/Details';
import ChatSimulator from 'pages/App/ChatSimulator';
import Agents from 'pages/App/Agents';
import AgentsList from 'pages/App/Agents/AgentsList';
import AgentDetailsLayout from 'pages/App/Agents/AgentDetails';
import AgentDetails from 'pages/App/Agents/AgentDetails/Details';
import AgentSystemMessage from 'pages/App/Agents/AgentDetails/SystemMessage';
import AgentKnowledgeBase from 'pages/App/Agents/AgentDetails/KnowledgeBase';
import Keywords from 'pages/App/Agents/AgentDetails/Keywords';
import AgentInsights from 'pages/App/Agents/AgentDetails/Insights';
import GlobalInsights from 'pages/App/Insights';
import Notifications from 'pages/App/Agents/AgentDetails/Notifications';
import KnowledgeBaseInsights from 'pages/App/Agents/AgentDetails/Insights/KnowledgeBase';
import CallAnalysis from 'pages/App/Agents/AgentDetails/Insights/CallAnalysis';
import AgentBilling from 'pages/App/Agents/AgentDetails/Billing';
import InsightsCallAnalysis from 'pages/App/Insights/CallAnalysis';
import InsightsTurnsByCall from 'pages/App/Insights/TurnsByCall';
import InsightsAgentPerformance from 'pages/App/Insights/AgentPerformance';
import InsightsUserPerformance from 'pages/App/Insights/UserPerformance';
import InsightsGrowthAnalysis from 'pages/App/Insights/GrowthAnalysis';
import ModelsList from 'pages/App/Models/ModelList';
import Models from 'pages/App/Models';
import Model from 'pages/App/Models/Model';
import ModelVersion from 'pages/App/Models/Model/Version';
import Campaigns from 'pages/App/Agents/AgentDetails/Campaigns';
import Features from 'pages/App/Agents/AgentDetails/Features';
import VersionInsights from 'pages/App/Models/Model/Version/Insights';
import VersionTopicAnalysis from 'pages/App/Models/Model/Version/Insights/topic';
import VersionIntentAnalysis from 'pages/App/Models/Model/Version/Insights/intent';
import VersionDataset from 'pages/App/Models/Model/Version/Dataset';
import VersionDetails from 'pages/App/Models/Model/Version/Details';
import VersionChat from 'pages/App/Models/Model/Version/Chat';
import CampaignDetails from 'pages/App/Agents/AgentDetails/Campaigns/CampaignDetails';
import Calls from 'pages/App/Agents/AgentDetails/Campaigns/CampaignDetails/Calls';
import CampaignDetailsSummary from 'pages/App/Agents/AgentDetails/Campaigns/CampaignDetails/Details';
import Tools from 'pages/App/Tools';
import Speech from 'pages/App/Speech';
import SpeechList from 'pages/App/Speech/SpeechServicesList';
import SpeechDetail from 'pages/App/Speech/SpeechServiceDetails';
import VoiceDetail from 'pages/App/Speech/VoiceDetail';
import Voices from 'pages/App/Speech/SpeechServiceDetails/voices';
import ModelsTab from 'pages/App/Speech/SpeechServiceDetails/models';
import ModelDetail from 'pages/App/Speech/ModelDetail';
import Insights from 'pages/App/History/ConversationDetails/Insights';
import {
    BRAND, SUCCESS, ERROR, WARNING,
} from './constants';

const theme = {
    rainbow: {
        palette: {
            brand: BRAND,
            success: SUCCESS,
            warning: WARNING,
            error: ERROR,
        },
    },
};

const App = () => (
    <RainbowFirebaseApp app={app} theme={theme}>
        <Routes>
            <Route
                path="/login"
                element={(
                    <WhenNoAuthenticated redirect="/">
                        <Login />
                    </WhenNoAuthenticated>
                )}
            />
            <Route
                path="/"
                element={(
                    <WhenAuthenticated path="/" redirect="/login">
                        <Outlet />
                    </WhenAuthenticated>
                )}
            >
                <Route path="/" element={<AppLayout />}>
                    <Route path="dataset" element={<DatasetLayout />}>
                        <Route path=":datasetId" element={<Dataset />}>
                            <Route path="" element={<Navigate to="groups" replace />} />
                            <Route path="groups" element={<DatasetGroups />} />
                            <Route path="building-blocks" element={<BuildingBlocks />} />
                            <Route path="settings" element={<DatasetSettings />} />
                        </Route>
                        <Route path=":datasetId/group/:groupId" element={<GroupDetails />} />
                        <Route path=":datasetId/group/:groupId/conversations" element={<GroupDetails />} />
                        <Route path=":datasetId/group/:groupId/conversation/:conversationId" element={<Conversation />} />
                    </Route>
                    <Route path="models" element={<Models />}>
                        <Route path="" element={<Navigate to="list" replace />} />
                        <Route path="list" element={<ModelsList />} />
                        <Route path=":modelId" element={<Model />} />
                        <Route path=":modelId/version/:versionId" element={<ModelVersion />}>
                            <Route path="" element={<Navigate to="details" replace />} />
                            <Route path="details" element={<VersionDetails />} />
                            <Route path="insights" element={<VersionInsights />}>
                                <Route path="" element={<Navigate to="intent" replace />} />
                                <Route path="intent" element={<VersionIntentAnalysis />} />
                                <Route path="topic" element={<VersionTopicAnalysis />} />
                            </Route>
                            <Route path="datasets" element={<VersionDataset />} />
                            <Route path="chat" element={<VersionChat />} />
                        </Route>
                    </Route>

                    <Route path="history" element={<History />}>
                        <Route path="" element={<Navigate to="conversations" replace />} />
                        <Route path="conversations" element={<Outlet />}>
                            <Route path="" element={<HistoryConversationsList />} />
                            <Route path=":conversationId" element={<HistoryConversationsDetailsLayout />}>
                                <Route path="" element={<Navigate to="debug" replace />} />
                                <Route path="debug" element={<HistoryConversationDebug />} />
                                <Route path="transcript" element={<HistoryConversationTranscript />} />
                                <Route path="details" element={<HistoryConversationDetails />} />
                                <Route path="insights" element={<Insights />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path="agents" element={<Agents />}>
                        <Route path="" element={<Navigate to="list" replace />} />
                        <Route path="list" element={<AgentsList />} />
                        <Route path=":agentId" element={<AgentDetailsLayout />}>
                            <Route path="" element={<Navigate to="details" replace />} />
                            <Route path="details" element={<AgentDetails />} />
                            <Route path="system-message" element={<AgentSystemMessage />} />
                            <Route path="knowledge-base" element={<AgentKnowledgeBase />} />
                            <Route path="notifications" element={<Notifications />} />
                            <Route path="keywords" element={<Keywords />} />
                            <Route path="billing" element={<AgentBilling />} />
                            <Route path="versions" element={<div>Versions History</div>} />
                            <Route path="insights" element={<AgentInsights />}>
                                <Route path="knowledge-base" element={<KnowledgeBaseInsights />} />
                                <Route path="call-analysis" element={<CallAnalysis />} />
                                <Route path="minutes-analysis" element={<div />} />
                            </Route>
                            <Route path="campaigns" element={<Campaigns />} />
                            <Route path="features" element={<Features />} />
                        </Route>
                        <Route path=":agentId/campaigns/:campaignId" element={<CampaignDetails />}>
                            <Route path="" element={<Navigate to="calls" replace />} />
                            <Route path="calls" element={<Calls />} />
                            <Route path="details" element={<CampaignDetailsSummary />} />
                        </Route>
                    </Route>
                    <Route path="speech" element={<Speech />}>
                        <Route path="" element={<Navigate to="list" replace />} />
                        <Route path="list" element={<SpeechList />} />
                        <Route path=":speechId" element={<SpeechDetail />}>
                            <Route path="" element={<Navigate to="voices" replace />} />
                            <Route path="voices" element={<Voices />} />
                            <Route path="models" element={<ModelsTab />} />
                        </Route>
                        <Route path=":speechId/voice/:voiceId" element={<VoiceDetail />} />
                        <Route path=":speechId/model/:modelId" element={<ModelDetail />} />
                    </Route>
                    <Route path="chat-simulator" element={<ChatSimulator />} />
                    <Route path="tests" element={<div />} />
                    <Route path="" element={<Navigate to="/dataset" />} />
                    <Route path="insights" element={<GlobalInsights />}>
                        <Route path="" element={<Navigate to="call-duration" replace />} />
                        <Route path="call-duration" element={<InsightsCallAnalysis />} />
                        <Route path="turns-by-call" element={<InsightsTurnsByCall />} />
                        <Route path="agent-performance" element={<InsightsAgentPerformance />} />
                        <Route path="user-performance" element={<InsightsUserPerformance />} />
                        <Route path="growth" element={<InsightsGrowthAnalysis />} />
                    </Route>
                    <Route path="tools" element={<Tools />} />
                </Route>
            </Route>
        </Routes>
    </RainbowFirebaseApp>
);

export default App;
