import { Event } from '../types';

const measureTimeBetween = (
    events: Event[],
    startEvent: string,
    endEvent: string,
): number[] => {
    let lastStartTime: number | null = null;
    return events.reduce((delays, event) => {
        const { event: currentType } = event.content;
        const timestamp = event.timeMark;

        if (currentType === startEvent) {
            lastStartTime = timestamp;
        } else if (currentType === endEvent && lastStartTime !== null) {
            delays.push(timestamp - lastStartTime);
            lastStartTime = null;
        }

        return delays;
    }, [] as number[]);
};

export default measureTimeBetween;
