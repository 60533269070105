import styled from 'styled-components';
import { NavigationButtonColumn } from '@rainbow-modules/listview';
import { ButtonIcon } from 'react-rainbow-components';
import { color } from 'theme';
import { BACKGROUND } from '../../../constants';

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    background-color: ${BACKGROUND};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 0px 0 16px;
`;

export const Content = styled.div`
    background: ${color('background.main')};
    height: 100%;
    margin-bottom: 16px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
    flex: 1;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    align-items: center;
`;

export const StyledNavigationButtonColumn = styled(NavigationButtonColumn)`
    margin-left: 12px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
`;

export const StyledColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-right: 4px;
    padding-left: 4px;
    height: 100%;
    gap: 4px;
`;

export const ModalTitle = styled.span`
    font-size: 22px;
    font-family: 'Lato Bold';
    color: ${color('text.main')};
    padding: 16px 20px;
`;

export const PageTitle = styled.h1`
    font-size: 14px;
    font-family: "Lato Bold";
    color: ${color('text.main')};
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const TrashButton = styled(ButtonIcon)`
    width: 30px;
    height: 30px;

    > svg {
        height: 16px !important;
        width: 16px !important;
    }
`;
