import { ParsedContent } from '../types';

interface Params {
    role: 'event' | 'assistant' | 'user';
    content: ParsedContent;
}

const EVENT_TYPES = {
    ANSWER_CALL: 'answer-call',
    USER_START_SPEAK: 'user-start-speak',
    USER_PAUSE_SPEAK: 'user-pause-speak',
    USER_RESUME_SPEAK: 'user-resume-speak',
    USER_END_SPEAK: 'user-end-speak',
    ASSISTANT_START_SPEAK: 'assistant-start-speak',
    ASSISTANT_STOP_SPEAK: 'assistant-stop-speak',
    START_TRANSCRIPT: 'start-transcript',
    PARTIAL_TRANSCRIPT: 'partial-transcript',
    FINAL_TRANSCRIPT: 'final-transcript',
    TRANSCRIPT_TIMEOUT: 'transcript-timeout',
    BARGE_IN: 'barge-in',
    AGENT_ABORT: 'agent-abort',
    CHANGE_LANGUAGE: 'change-language',
    TRANSCRIPTION_CONNECTION_OPEN: 'transcription-connection-open',
    TRANSCRIPTION_CONNECTION_CLOSE: 'transcription-connection-close',
    HANG_UP: 'hang-up',
};

const EventDescriptionMap: Record<string, string> = {
    [EVENT_TYPES.ANSWER_CALL]: `
Event triggered at the very start of the call. Contains information about the:
- **customer**
- **phone number**
- **agent**
- **speech service**
- **transcription service**
    `,
    [EVENT_TYPES.USER_START_SPEAK]: `
Triggered when user speech is detected. Does not contain any extra information 
beside the regular event data (time mark, etc).
    `,
    [EVENT_TYPES.USER_PAUSE_SPEAK]: `
An event that signals the user has made a pause in their speech. From here, the user can:
- Resume speaking, or
- After some time, an \`user-end-speak\` event will fire
    `,
    [EVENT_TYPES.USER_RESUME_SPEAK]: `
Triggered when the user starts to speak again after a pause. In the call flow, this cancels
any processing of a response, since we know we don't have the full user speech yet.
    `,
    [EVENT_TYPES.USER_END_SPEAK]: `
This event is triggered when the user stopped speaking for at least X time, defined in the
agent configuration. After this event:
- The call flow proceeds to send any response that it already has prepared
- The agent response will never be sent to the user until this event occurs
    `,
    [EVENT_TYPES.ASSISTANT_START_SPEAK]: `
Triggered when the AI's response speech is sent to the user. Always occurs after \`user-end-speak\`.
    `,
    [EVENT_TYPES.ASSISTANT_STOP_SPEAK]: `
Triggered when the last chunk of audio finishes playing.
    `,
    [EVENT_TYPES.START_TRANSCRIPT]: `
An event that signals the user speech starts being sent to the transcription service,
usually at the same time as \`user-start-speak\`.
    `,
    [EVENT_TYPES.PARTIAL_TRANSCRIPT]: `
This event notifies that a partial transcript has been received. This transcript can be:
- A part of what the user has spoken until the moment, or
- A full transcription that is still not formatted

Contains the transcribed text.

> **Note:** The models can generate a final transcript at any time when it considers a text
> cannot be further processed. This can cause the partial transcript to reset to a string
> starting at the point of the last final transcript.
    `,
    [EVENT_TYPES.FINAL_TRANSCRIPT]: `
Notifies that a final transcript has been received. Contains:
- The transcribed text
- The confidence of the transcript
- Detailed information of each word

After this event the transcribed text is sent to the LLM optimistically to start generating
the response, but this response can be reset if the system receives another transcript before
the response is sent to the user.

> **Note:** The models can generate a final transcript at any time when it considers a text
> cannot be further processed. The controller handles this and is capable of concatenating
> multiple final transcripts before sending a response to the user.
    `,
    [EVENT_TYPES.TRANSCRIPT_TIMEOUT]: `
This event occurs when a final transcription is not received within a set time after the
\`user-end-speak\` event. After the event, the system prepares a predefined message to
inform the user that we could not understand what they said.
    `,
    [EVENT_TYPES.BARGE_IN]: `
An event triggered when the user interrupts the assistant. The system:
- Cancels all processing
- Stops playing audio
- Starts listening to the user
    `,
    [EVENT_TYPES.AGENT_ABORT]: `
Triggers when the generation of a response is cancelled on the LLM. This happens when:
- The user resumes speaking, or
- A new transcription arrives
    `,
    [EVENT_TYPES.CHANGE_LANGUAGE]: `
This event is triggered when the system detects that the user is now speaking in a
different language. This causes all predefined messages to use the new language.
    `,
    [EVENT_TYPES.TRANSCRIPTION_CONNECTION_OPEN]: `
Notifies that a new connection to the transcription service was created. Relevant because
each connection takes some time to establish, which adds delay to the response.
    `,
    [EVENT_TYPES.TRANSCRIPTION_CONNECTION_CLOSE]: `
Notifies that the connection to the transcription service was closed. At the moment of
writing this, the connection is always closed after \`user-end-speak\`.
    `,
    [EVENT_TYPES.HANG_UP]: `
Notifies that the call has ended.
    `,
};

const getEventDescription = ({ role, content }: Params) => {
    if (role === 'event') {
        return EventDescriptionMap[
            (content as ParsedContent)?.event as keyof typeof EventDescriptionMap
        ] || 'Unknown event';
    }
    return '';
};

export default getEventDescription;
