import { doc, getDoc, getDocFromServer } from "firebase/firestore";
import { db } from "../../firebase";
import { GetServiceOpts, EntityGet, EntityServerGet } from "../../types";
import { Voices } from "./types";
import { transformMetadata, processTimestampFields } from "../../utils";

export type VoicesGetService<T> = (
  speechId: string,
  id: string,
  opts?: GetServiceOpts
) => Promise<T | null>;

const get: VoicesGetService<EntityGet<Voices>> = async (
  speechId,
  id,
  opts = {}
) => {
  const docRef = doc(db, `/speech/${speechId}/voices/${id}`);
  const { disableCache } = opts;
  const getFn = disableCache ? getDocFromServer : getDoc;
  const docSnap = await getFn(docRef);
  if (docSnap.exists()) {
    return processTimestampFields(
      transformMetadata({
        ...docSnap.data(),
        id: docSnap.id,
      } as EntityServerGet<Voices>)
    );
  }
  return null;
};

export default get;
