import { useParams } from 'react-router-dom';
import { Spinner } from 'react-rainbow-components';
import { ResponsiveBar } from '@nivo/bar';
import styled from 'styled-components';
import useHttpQuery from '../../../../../../data/firestore/useHttpQuery';
import {
    ResourcesContainer,
    ResourceCard,
    ChartContainer,
} from './styles';
import ResourceHeader from './ResourceHeader';
import Tile from '../../../../../../components/BetterTile';

interface UsagePoint {
    date: string;
    quantity: number;
}

interface Resource {
    name: string;
    description: string;
    unit: string | null;
    usage: UsagePoint[];
}

interface Usage {
    resources: Resource[];
    currentPeriodStart: number;
    currentPeriodEnd: number;
}

const TilesContainer = styled.div`
    display: flex;
    gap: 16px;
`;

const MetersUsage = () => {
    const { agentId } = useParams();
    const {
        data,
        isLoading,
    } = useHttpQuery<Usage>({
        pathname: `/agents/${agentId}/subscription/usage`,
        method: 'GET',
        server: process.env.REACT_APP_BASE_URL,
    });

    if (isLoading) {
        return <Spinner />;
    }
    const { currentPeriodStart, currentPeriodEnd } = data as Usage;

    const formattedDate = new Date(currentPeriodEnd * 1000).toLocaleDateString(undefined, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });

    return (
        <ResourcesContainer>
            <TilesContainer>
                <Tile
                    label="Next Billing Date"
                    value={formattedDate}
                />
            </TilesContainer>
            {data?.resources.map((resource: Resource) => {
                // Create array of all dates between period start and end
                const startDate = new Date(currentPeriodStart * 1000);
                const endDate = new Date(currentPeriodEnd * 1000);
                const dateArray = [];

                // Reset startDate to beginning of day
                startDate.setHours(0, 0, 0, 0);

                // Create map of existing usage data
                const usageMap = new Map(
                    resource.usage.map((point) => [
                        new Date(point.date).toISOString().split('T')[0],
                        point.quantity,
                    ]),
                );

                // Generate all dates and their values
                const currentDate = new Date(startDate);
                while (currentDate <= endDate) {
                    const dateStr = currentDate.toISOString().split('T')[0];
                    dateArray.push({
                        date: currentDate.toLocaleDateString(undefined, {
                            month: 'short',
                            day: 'numeric',
                        }),
                        quantity: usageMap.get(dateStr) || 0,
                        rawDate: dateStr, // Used for sorting
                    });
                    currentDate.setDate(currentDate.getDate() + 1);
                }

                // Sort by date
                const plotData = dateArray.sort((a, b) => a.rawDate.localeCompare(b.rawDate));

                const values = plotData.map((point) => point.quantity);
                const totalQuantity = values.reduce((sum, value) => sum + value, 0);

                return (
                    <ResourceCard key={resource.name}>
                        <ResourceHeader
                            name={resource.name}
                            description={resource.description}
                        />
                        <Tile
                            label="Total Quantity"
                            value={totalQuantity}
                            unit={resource.unit || undefined}
                        />
                        <ChartContainer>
                            <ResponsiveBar
                                data={plotData}
                                keys={['quantity']}
                                indexBy="date"
                                padding={0.3}
                                colors={['#0073e6']}
                                borderRadius={4}
                                margin={{
                                    top: 20,
                                    right: 20,
                                    bottom: 50,
                                    left: 60,
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: resource.unit || 'Count',
                                    legendPosition: 'middle',
                                    legendOffset: -40,
                                }}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: -45,
                                }}
                                enableLabel={false}
                                animate
                                motionConfig="gentle"
                                minValue={0}
                                maxValue={Math.max(...values) * 1.1}
                            />
                        </ChartContainer>
                    </ResourceCard>
                );
            })}
        </ResourcesContainer>
    );
};

export default MetersUsage;
