import { useState, useRef } from 'react';
import { RenderIf, InternalTooltip } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import { EntityGet } from 'data/firestore/types';
import { Conversation } from 'data/firestore/session/conversation/types';
import CognitiveLoadBadge from '../CognitiveLoadBadge';
import SentimentBadge from '../SentimentBadge';
import useSentimentAnalysis from '../../hooks/useSentimentAnalysis';
import useCognitiveLoad from '../../hooks/useCognitiveLoad';
import {
    MessageContainer,
    RoleContainer,
    MessageContent,
    MetadataContainer,
    StyledBadge,
    NativeButton,
    MoreDetailsContainer,
    DescriptionContainer,
    DescriptionText,
    DescriptionTitle,
    Row,
    RoleBadge,
    Word,
} from './styled';

const WordWithConfidence = ({ word, confidence }: { word: string, confidence?: number }) => {
    const [isHover, setHover] = useState(false);
    const triggerRef = useRef<HTMLElement | null>(null);
    const hasConfidence = typeof confidence === 'number';

    const confidencePercentage = hasConfidence ? Math.round(confidence * 100) : 0;

    return (
        <Word
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            ref={triggerRef}
            value={confidence}
            isHover={isHover}
        >
            {word}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <InternalTooltip
                triggerElementRef={() => triggerRef}
                isVisible={hasConfidence && isHover}
                preferredPosition="top"
            >
                {`Confidence: ${confidencePercentage}%`}
            </InternalTooltip>
        </Word>
    );
};

const tokenize = (text: string) => text.split(/([^\wáéíóúüñÁÉÍÓÚÜÑ]+|\s+)/).filter(Boolean);

const ContentWithConfidence = ({ data }: { data: EntityGet<Conversation> }) => {
    const { role, content, speechToText } = data;
    if (role === 'user') {
        const tokens = tokenize(content);
        // Create a mutable copy of the words array
        const wordsWithConfidence = speechToText?.words ? [...speechToText.words] : [];

        return (
            <>
                {tokens.map((token, index) => {
                    const key = `${token}-${index}`;

                    // Find the index of the word in the mutable array
                    const wordIndex = wordsWithConfidence.findIndex(
                        (item) => item.word === token.toLowerCase(),
                    );

                    let wordObj;
                    if (wordIndex !== -1) {
                        // If found, remove the word from the array
                        [wordObj] = wordsWithConfidence.splice(wordIndex, 1);
                    }

                    return (
                        <WordWithConfidence
                            key={key}
                            word={token}
                            confidence={wordObj ? wordObj.confidence : undefined}
                        />
                    );
                })}
            </>
        );
    }
    return <span>{content}</span>;
};

interface Props {
    data: EntityGet<Conversation>;
}

const Message = ({ data }: Props) => {
    const {
        id, role, speechToText,
    } = data;
    const [showMoreDetails, setShowMoreDetails] = useState(false);

    const { conversationId = '' } = useParams();
    const collectionPath = `/session/${conversationId}/conversation`;
    const sentiment = useSentimentAnalysis(id, collectionPath);
    const cognitiveLoad = useCognitiveLoad(id, collectionPath);
    const confidence = speechToText?.confidence || 0;

    const hasBadges = (role === 'user' && confidence) || sentiment || cognitiveLoad;

    return (
        <MessageContainer>
            <Row>
                <RoleContainer>
                    <RoleBadge label={role} borderRadius="semi-square" />
                </RoleContainer>
                <MessageContent>
                    <ContentWithConfidence data={data} />
                </MessageContent>

            </Row>
            {hasBadges && (
                <MetadataContainer>
                    {role === 'user' && confidence > 0 && (
                        <StyledBadge
                            label={`Conf: ${(confidence * 100).toFixed(0)}%`}
                            value={confidence}
                            borderRadius="semi-square"
                        />
                    )}
                    {cognitiveLoad && (
                        <NativeButton
                            type="button"
                            title={showMoreDetails ? 'Hide details' : 'Show cognitive load details'}
                            onClick={(event) => {
                                event.stopPropagation();
                                setShowMoreDetails(!showMoreDetails);
                            }}
                        >
                            <CognitiveLoadBadge cognitiveLoad={cognitiveLoad} />
                        </NativeButton>
                    )}
                    {sentiment && <SentimentBadge sentiment={sentiment} />}
                </MetadataContainer>
            )}
            <RenderIf isTrue={showMoreDetails}>
                <MoreDetailsContainer>
                    <DescriptionContainer>
                        <DescriptionTitle>
                            Why the cognitive load is:
                            {' '}
                            {cognitiveLoad?.cognitiveLoad}
                            ?
                        </DescriptionTitle>
                        <DescriptionText>
                            {cognitiveLoad?.why}
                        </DescriptionText>
                    </DescriptionContainer>
                </MoreDetailsContainer>
            </RenderIf>
        </MessageContainer>
    );
};

export default Message;
