import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Breadcrumbs, Breadcrumb, RenderIf, LoadingShape,
} from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import { RecordField } from '@rainbow-modules/record';
import PageHeader from 'components/PageHeader';
import useSpeech from 'data/firestore/speech/use';
import useModel from 'data/firestore/speech/models/use';
import { PageTitle } from '../styled';
import {
    Content, InfoContainer, StyledSpinner,
} from '../VoiceDetail/styled';

const ModelDetail = () => {
    const { speechId = '', modelId = '' } = useParams();
    const [speechName, setSpeechName] = useState('');
    const navigate = useNavigate();
    const { data: speech } = useSpeech(speechId);
    const { data: model, isLoading } = useModel(speechId, modelId);
    const [languageNames, setLanguageNames] = useState<string[]>([]);

    useEffect(() => {
        if (speech) {
            setSpeechName(speech.name);
        }
    }, [speech]);

    useEffect(() => {
        if (model?.languages) {
            const intlLanguages = new Intl.DisplayNames(model.languages, { type: 'language' });
            const languageNamesList = model.languages.map((lang) => intlLanguages.of(lang));
            setLanguageNames(languageNamesList.filter((lang) => lang !== undefined) as string[]);
        }
    }, [model]);

    return (
        <div>
            <PageHeader title={(
                <Breadcrumbs>
                    <Breadcrumb
                        label="All Speech Services"
                        onClick={() => navigate('/speech/list')}
                    />
                    <Breadcrumb
                        label={(
                            <>
                                <RenderIf isTrue={!isEmpty(speechName)}>
                                    <PageTitle>{speechName}</PageTitle>
                                </RenderIf>
                                <RenderIf isTrue={isEmpty(speechName)}>
                                    <LoadingShape />
                                </RenderIf>
                            </>
                        )}
                        onClick={() => navigate(`/speech/${speechId}/models`)}
                    />
                    <Breadcrumb label={(
                        <PageTitle>
                            {model?.name || modelId}
                        </PageTitle>
                    )}
                    />
                </Breadcrumbs>
            )}
            />

            <Content>
                <RenderIf isTrue={isLoading}>
                    <StyledSpinner size="small" type="arc" variant="brand" />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <PageTitle>Model Details</PageTitle>
                    <InfoContainer>
                        <div>
                            <RecordField
                                label="Model ID:"
                                value={model?.id}
                            />
                            {model?.description && (
                                <RecordField
                                    label="Description:"
                                    value={model?.description}
                                />
                            )}
                        </div>
                        <div>
                            <RecordField
                                label="Model Name:"
                                value={model?.name}
                            />
                            {model?.languages && (
                                <RecordField
                                    label="Languages:"
                                    value={languageNames.join(', ')}
                                />
                            )}
                        </div>
                    </InfoContainer>
                </RenderIf>
            </Content>
        </div>
    );
};

export default ModelDetail;
