import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
`;

export const CardContainer = styled.div`
    background: white;
    border-radius: 12px;
    padding: 24px;
    width: 100%;
    border: 2px solid #e6e8eb;
    transition: border-color 0.2s ease;

    &:hover {
        border-color: #0073e6;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
`;

export const HeaderText = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin: 0px;
    color: rgb(26, 26, 26);
    letter-spacing: -0.5px;
    line-height: 1.2;
`;

export const ChartContainer = styled.div`
    height: 300px;
    width: 100%;
    padding: 16px;
`;
