import Badge from 'components/Badge';
import { CellWrapper } from '../styled';
import { Invoice } from '../types';

interface Props {
    value?: Invoice['status'];
}

const mapStatusToBadge = (status?: Invoice['status']) => {
    switch (status) {
        case 'paid':
            return 'success';
        case 'draft':
            return 'warning';
        case 'uncollectible':
            return 'error';
        case 'void':
            return 'warning';
        default:
            return 'default';
    }
};

const mapStatusToLabel = (status?: Invoice['status']) => {
    switch (status) {
        case 'paid':
            return 'Paid';
        case 'draft':
            return 'Draft';
        case 'uncollectible':
            return 'Uncollectible';
        case 'void':
            return 'Void';
        default:
            return 'Unknown';
    }
};

const StatusColumn = ({ value }: Props) => (
    <CellWrapper>
        <Badge variant={mapStatusToBadge(value)} borderRadius="semi-square" label={mapStatusToLabel(value)} />
    </CellWrapper>
);

export default StatusColumn;
