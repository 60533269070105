import styled from 'styled-components';

export const Container = styled.div`
    border: 1px solid rgba(215, 217, 226, 0.8);
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    padding: 16px;
    max-width: 320px;
    font-size: 13px;
    
    &:hover {
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
        transform: translateY(-2px);
    }
`;

export const ProcessName = styled.h1<{ role?: string }>`
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    margin: 0 0 12px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(215, 217, 226, 0.5);
    color: ${({ role }) => {
        switch (role) {
            case 'user': return '#2D5BFF';
            case 'assistant': return '#6E56CF';
            case 'synthesize': return '#46A758';
            case 'agent-call': return '#8250DF';
            case 'barge-in': return '#F76808';
            case 'final-transcript': return '#7D7D7D';
            default: return '#5c5c5c';
        }
    }};
    text-transform: capitalize;
    letter-spacing: -0.01em;
`;

export const FieldValue = styled.div`
    font-family: 'Inter', sans-serif;
    line-height: 1.5;
    margin-top: 4px;
    color: #333;
    word-break: break-word;
    
    &.confidence-high {
        color: #46A758;
    }
    
    &.confidence-medium {
        color: #F5A524;
    }
    
    &.confidence-low {
        color: #E54D2E;
    }
`;

export const FieldLabel = styled.div`
    font-weight: 500;
    color: #6E6E6E;
    font-size: 12px;
`;

export const FieldContainer = styled.div`
    margin-bottom: 10px;
`;
