import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Table, Column, RenderIf, Input,
    Button, ButtonIcon,
} from 'react-rainbow-components';
import { Trash } from 'components/icons';
import { MagnifyingGlass, Plus } from '@rainbow-modules/icons';
import useModels from 'data/firestore/speech/models/useCollection';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { confirmModal, showAppMessage } from '@rainbow-modules/app';
import {
    Header, StyledNavigationButtonColumn, Actions,
} from '../styled';
import AddModelModal from './addModelModal';

const DeleteModelButton = ({ row, onDelete }: { row?: any; onDelete: (id: string) => void }) => (
    <ButtonIcon
        icon={<Trash />}
        borderRadius="semi-rounded"
        onClick={(e) => {
            e.stopPropagation();
            if (row) onDelete(row.id);
        }}
    />
);

const Models = () => {
    const { speechId = '' } = useParams();
    const { data, isLoading } = useModels(speechId as string);
    const navigate = useNavigate();
    const navigateToModel = (value: any) => {
        navigate(`/speech/${speechId}/model/${value.row.id}`);
    };
    const [isOpenAddModelModal, setIsOpenAddModelModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const hasModels = data?.length > 0;

    // Filter data based on search term
    const filteredData = data?.filter((model) => (
        searchTerm ? model.name?.toLowerCase().includes(searchTerm.toLowerCase()) : true
    ));

    const { mutateAsync: deleteModel, isLoading: isDeleting } = useHttpMutation({
        method: 'DELETE',
    });

    const handleDeleteModel = async (modelId: string) => {
        const confirmed = await confirmModal({
            header: 'Delete Model',
            question: 'Are you sure you want to delete this model?',
            variant: 'destructive',
            okButtonLabel: 'Delete',
            cancelButtonLabel: 'Cancel',
        });

        if (confirmed) {
            try {
                await deleteModel({
                    pathname: `/speech/${speechId}/models/${modelId}`,
                });
                showAppMessage({
                    variant: 'success',
                    timeout: 5000,
                    message: 'Model deleted successfully',
                });
            } catch (error) {
                showAppMessage({
                    variant: 'error',
                    timeout: 5000,
                    message: 'Failed to delete model',
                });
            }
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    return (
        <>
            <Header>
                <Input
                    size="small"
                    borderRadius="semi-rounded"
                    placeholder="Find a model..."
                    icon={<MagnifyingGlass />}
                    type="search"
                    value={searchTerm}
                    onChange={handleSearch}
                />

                <Actions>
                    <Button
                        variant="brand"
                        size="small"
                        borderRadius="semi-rounded"
                        onClick={() => setIsOpenAddModelModal(true)}
                    >
                        <Plus className="rainbow-m-right_small" />
                        Add Model
                    </Button>
                </Actions>
            </Header>
            <RenderIf isTrue={isLoading || hasModels}>
                <Table
                    data={filteredData}
                    isLoading={isLoading || isDeleting}
                    keyField="id"
                    variant="listview"
                    className="rainbow-m-left_large rainbow-m-right_large"
                >
                    <Column
                        header="Model"
                        field="name"
                        headerAlignment="left"
                        cellAlignment="left"
                        component={StyledNavigationButtonColumn}
                        onClick={navigateToModel}
                    />
                    <Column
                        width={100}
                        headerAlignment="center"
                        cellAlignment="center"
                        component={DeleteModelButton}
                        onDelete={handleDeleteModel}
                    />
                </Table>
            </RenderIf>
            <AddModelModal
                isOpen={isOpenAddModelModal}
                onRequestClose={() => setIsOpenAddModelModal(false)}
                speechId={speechId}
                existingModelsIds={data?.map((model) => model.id) || []}
            />
        </>
    );
};

export default Models;
