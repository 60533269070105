import styled from 'styled-components';
import { Spinner } from 'react-rainbow-components';

export const Content = styled.div`
    padding: 20px;
    margin: 0 auto;
`;

export const InfoContainer = styled.div`
    display: flex;
    margin-top: 20px;
    gap: 40px;
    
    > div {
        flex: 1;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledSpinner = styled(Spinner)`
    display: flex;
    justify-content: center;
    margin: 40px auto;
`;
