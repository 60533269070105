import styled from 'styled-components';
import { RecordField } from '@rainbow-modules/record';

export const SubscriptionContainer = styled.div`
    background: white;
    border-radius: 10px;
    padding: 16px;
    border: 1px solid #e5e7eb;
    /* Reduced shadow for subtle depth while having border */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
`;

export const SubscriptionGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

export const FieldGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyledRecordField = styled(RecordField)`
    display: flex;
    flex-direction: column;

    & > span:first-child {
        font-size: 0.75rem;
        color: #94a3b8;
        font-weight: 600;
        margin-bottom: 4px;
        text-transform: uppercase;
    }

    & > span:last-child {
        font-size: 0.875rem;
        color: #1e293b;
        font-weight: 500;
        line-height: 1.25;
    }
`;

export const StatusBadge = styled.div<{ status: string }>`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 0.75rem;
    font-weight: 500;
    
    ${({ status }) => {
        if (status === 'active') {
            return `
                background-color: #dcfce7;
                color: #166534;
            `;
        }
        if (['canceled', 'unpaid', 'past_due', 'incomplete', 'incomplete_expired'].includes(status)) {
            return `
                background-color: #fee2e2;
                color: #991b1b;
            `;
        }
        return `
            background-color: #f3f4f6;
            color: #4b5563;
        `;
    }}
`;
