import ButtonIcon from 'components/ButtonIcon';
import Download from 'components/icons/download';
import { ActionsCellWrapper } from '../styled';
import { Invoice } from '../types';

interface Props {
    row?: Invoice;
}

const Actions = ({ row }: Props) => (
    <ActionsCellWrapper>
        <ButtonIcon
            borderRadius="semi-square"
            icon={<Download />}
            tooltip="Download invoice as pdf"
            size="small"
            onClick={() => {
                window.open(row?.pdf as string, '_blank');
            }}
        />
    </ActionsCellWrapper>
);

export default Actions;
