import styled from 'styled-components';

export const PriceTableContainer = styled.div`
  margin-top: 16px;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  overflow: hidden;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #E5E7EB;
  
  &:last-child {
    border-bottom: none;
  }
  
  &:hover {
    background-color: #F9FAFB;
  }
`;

export const TableHeader = styled.th`
  text-align: left;
  padding: 10px 16px;
  color: #6B7280;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background-color: #F9FAFB;
`;

export const TableCell = styled.td`
  padding: 10px 16px;
  color: #111827;
`;
