import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Breadcrumbs, Breadcrumb, RenderIf, LoadingShape,
} from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import { RecordField } from '@rainbow-modules/record';
import PageHeader from 'components/PageHeader';
import getSpeech from 'data/firestore/speech/get';
import getVoice from 'data/firestore/speech/voices/get';
import { PageTitle } from '../styled';
import {
    Content, InfoContainer, StyledSpinner,
} from './styled';

const VoiceDetail = () => {
    const { speechId = '', voiceId = '' } = useParams();
    const [speechName, setSpeechName] = useState('');
    const [voice, setVoice] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            try {
                setIsLoading(true);
                const speech = await getSpeech(speechId);
                setSpeechName(speech?.name || speechId);

                const voiceData = await getVoice(speechId, voiceId);
                setVoice(voiceData);
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, [speechId, voiceId]);

    return (
        <div>
            <PageHeader title={(
                <Breadcrumbs>
                    <Breadcrumb
                        label="All Speech Services"
                        onClick={() => navigate('/speech/list')}
                    />
                    <Breadcrumb
                        label={(
                            <>
                                <RenderIf isTrue={!isEmpty(speechName)}>
                                    <PageTitle>{speechName}</PageTitle>
                                </RenderIf>
                                <RenderIf isTrue={isEmpty(speechName)}>
                                    <LoadingShape />
                                </RenderIf>
                            </>
                        )}
                        onClick={() => navigate(`/speech/${speechId}/voices`)}
                    />
                    <Breadcrumb label={(
                        <PageTitle>
                            {voice?.name || voiceId}
                        </PageTitle>
                    )}
                    />
                </Breadcrumbs>
            )}
            />

            <Content>
                <RenderIf isTrue={isLoading}>
                    <StyledSpinner size="small" type="arc" variant="brand" />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <PageTitle>Voice Details</PageTitle>
                    <InfoContainer>
                        <div>
                            <RecordField
                                label="Voice ID:"
                                value={voice?.id}
                            />
                            <RecordField
                                label="Voice Name:"
                                value={voice?.name}
                            />
                        </div>
                    </InfoContainer>
                </RenderIf>
            </Content>
        </div>
    );
};

export default VoiceDetail;
